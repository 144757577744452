import { useState } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Row, Col, Button, Modal, message, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';

import http from '@/utils/http';
import ModalForm from './ModalForm';

const ClientLevel = () => {
    const [list, setList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [editItem, setEditItem] = useState({});

    const getList = () => {
        return new Promise(resolve => {
            http.post('/api/level/listAll').then(res => {
                resolve(res)
            })
        })
    }
    const { loading, run } = useRequest(getList, {
        onSuccess: res => {
            setList(res)
        }
    });

    const listColumns = [
        { title: '级别名称', dataIndex: 'name', align: 'center' },
        { title: '分润比例', dataIndex: 'profitRatio', align: 'center', render: text => `${text} %` },
        { title: '提成比例', dataIndex: 'tcRatio', align: 'center', render: text => `${text} %` },
        { title: '分佣金额', dataIndex: 'commissionMoney', align: 'center', render: text => `${text} $` },
        {
            title: '操作', align: 'center', width: 120,
            render: record => (
                <div className="table-actions">
                    <Button size="small" type="link" onClick={ edit.bind(this, record) }>编辑</Button>
                    <Button size="small" type="link" onClick={ delItem.bind(this, record.id) }>删除</Button>
                </div>
            )
        }
    ]
    const tableProps = {
        dataSource: list,
        loading,
        pagination: list.length < 11 ? false : true
    }
    // 新增 & 编辑
    const create = () => {
        setVisible(true)
        setModalTitle('新增')
        setEditItem({})
    }
    const edit = record => {
        setVisible(true)
        setModalTitle('编辑')
        setEditItem(record)
    }
    const onQuery = values => {
        let url = '/api/level/add'
        let data = {...values}

        if(editItem.id) {
            url = '/api/level/update'
            data.id = editItem.id
        }

        http.post(url, data, { isLoading: true }).then(res => {
            message.success(`${modalTitle}成功`)
            run()
            onCancel()
        })
    }
    const onCancel = () => {
        setVisible(false)
    }
    // 删除
    const delItem = id => {
        Modal.confirm({
            title: '提示',
            content: '确认要删除？',
            onOk: () => {
                let data = {
                    id
                }
                http.post('/api/level/delete', data, { isLoading: true }).then(res => {
                    message.success('删除成功！')
                    run()
                })
            }
        })
    }
    return (
        <div className="content">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/home">首页</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>客户级别</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="table-title" justify="space-between">
                <Col></Col>
                <Col>
                    <Button type="primary" onClick={ create }><PlusOutlined />新增</Button>
                </Col>
            </Row>
            <Table bordered size="small" rowKey="id" columns={ listColumns } {...tableProps} />
            <ModalForm
                visible={ visible }
                title={ modalTitle }
                detail={ editItem }
                onOk={ onQuery }
                onCancel={ onCancel }
            />
        </div>
    )
}
export default ClientLevel;