import { useState, useEffect } from 'react';
import { Row, Col, Modal, Spin } from 'antd';
import { useRequest } from 'ahooks';
import http from '@/utils/http';
import UploadFile from './UploadFile';

const ClientFiles = props => {
    const [fileType, setFileType] = useState(undefined);
    const [fileList1, setFileList1] = useState([]);
    const [fileList2, setFileList2] = useState([]);
    const [fileList3, setFileList3] = useState([]);
    const [fileList4, setFileList4] = useState([]);
    const [fileList5, setFileList5] = useState([]);

    useEffect(() => {
        if(!props.visible) {
            return
        }
        setFileType(undefined);
        setFileList1([]);
        setFileList2([]);
        setFileList3([]);
        setFileList4([]);
        setFileList5([]);
        run()
    }, [props.visible]) // eslint-disable-line

    const getList = () => {
        return new Promise(resolve => {
            let data = {
                id: props.clientId
            }
            http.post('/api/client/fileList', data).then(res => {
                resolve(res)
            })
        })
    }
    const { loading, run } = useRequest(getList, {
        manual: true,
        onSuccess: res => {
            let list1 = [];
            let list2 = [];
            let list3 = [];
            let list4 = [];
            let list5 = [];
            let type = null
            res.forEach(item => {
                if (item.type === '1') {
                    list1.push(item)
                }
                if (item.type === '2') {
                    list2.push(item)
                }
                if (item.type === '3') {
                    list3.push(item)
                }
                if (item.type === '4') {
                    list4.push(item)
                }
                if (item.type > 4) {
                    list5.push(item)
                    type = item.type
                }
            })
            setFileType(type)
            setFileList1(list1)
            setFileList2(list2)
            setFileList3(list3)
            setFileList4(list4)
            setFileList5(list5)
        }
    })
    return (
        <Modal
            title="证件管理"
            width="900px"
            centered
            visible={ props.visible }
            onCancel={ props.onCancel }
            footer={ null }
            destroyOnClose
        >
            <Spin spinning={ loading }>
                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <UploadFile
                            title="身份证人像面"
                            type="1"
                            fileList={ fileList1 }
                            clientId={ props.clientId }
                        />
                    </Col>
                    <Col span={12}>
                        <UploadFile
                            title="身份证国徽面"
                            type="2"
                            fileList={ fileList2 }
                            clientId={ props.clientId }
                        />
                    </Col>
                    <Col span={12}>
                        <UploadFile
                            title="银行卡正面"
                            type="3"
                            fileList={ fileList3 }
                            clientId={ props.clientId }
                        />
                    </Col>
                    <Col span={12}>
                        <UploadFile
                            title="银行卡背面"
                            type="4"
                            fileList={ fileList4 }
                            clientId={ props.clientId }
                        />
                    </Col>
                    <Col span={12}>
                        <UploadFile
                            title="附加"
                            type={ fileType } 
                            fileList={ fileList5 }
                            clientId={ props.clientId }
                        />
                    </Col>
                </Row>
            </Spin>
        </Modal>
    )
}

export default ClientFiles;