import { useState, useEffect } from 'react';
import { Modal, Form, Select, InputNumber, DatePicker } from 'antd';

import moment from 'moment';
import http from '@/utils/http';

const ModalForm = props => {
    const [ form ] = Form.useForm();
    const [accountList, setAccountList] = useState([]);
    const [typeCode, setTypeCode] = useState('');
    const [accountTargetList, setAccountTargetList] = useState([]);

    useEffect(() => {
        if(!props.visible){
            return
        }
        form.resetFields();
        form.setFieldsValue(props.detail);
        setTypeCode(props.detail.typeCode);

    }, [props.visible]) // eslint-disable-line

    const queryForm = () => {
        form.validateFields().then(values => {
            values.platformCode = values.platform.key;
            values.platformName = values.platform.label;
            values.typeCode = values.type.key;
            values.typeName = values.type.label;
            values.accountId = values.accountItem.key;
            values.account = values.accountItem.label.substring(0, values.accountItem.label.indexOf(' ('));
            values.accountTargetId = values.accountTargetItem ? values.accountTargetItem.key : undefined;
            values.accountTarget = values.accountTargetItem ? values.accountTargetItem.label.substring(0, values.accountTargetItem.label.indexOf(' (')) : undefined;
            delete values.platform;
            delete values.type;
            delete values.accountItem;
            delete values.accountTargetItem;
            values.operationDate = moment(values.operationDate).format('YYYY-MM-DD');

            props.onOk(values)
        })
    }
    const cancel = () => {
        props.onCancel()
    }

    const changeType = item => {
        setTypeCode(item.key)
        if(item.key === '3') {
            form.setFieldsValue({
                accountTargetItem: undefined
            })

            let account = accountList.filter(a => a.id === item.key)[0];
            let clientId = account.clientId;
            let targetList = accountList.filter(a => a.clientId === clientId && a.id !== item.key);
            setAccountTargetList(targetList)
        }
    }
    const changePlatform = platform => {
        let code = platform.key
        getAccounts(code)
        form.setFieldsValue({
            accountItem: undefined
        })
        if(typeCode === '3') {
            form.setFieldsValue({
                accountTargetItem: undefined
            })
        }
    }

    const getAccounts = code => {
        let data = {
            platformCode: code
        }
        http.post('/api/account/list', data).then(res => {
            setAccountList(res)
        })
    }
    const changeAccount = item => {
        if(typeCode !== '3') {
            return
        }
        let account = accountList.filter(a => a.id === item.key)[0];
        let clientId = account.clientId;
        let targetList = accountList.filter(a => a.clientId === clientId && a.id !== item.key);
        setAccountTargetList(targetList)

        form.setFieldsValue({
            accountTargetItem: undefined
        })
    }


    return (
        <Modal
            title={ props.title }
            centered
            visible={ props.visible }
            onOk={ queryForm }
            onCancel={ cancel }
            forceRender
        >
            <Form form={ form } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} autoComplete="off">
                <Form.Item
                    label="日期"
                    name="operationDate"
                    rules={[{ required: true, message: '请选择日期' }]}
                >
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="类型"
                    name="type"
                    rules={[{ required: true, message: '请选择类型' }]}
                >
                    <Select labelInValue placeholder="请选择类型" onChange={ changeType }>
                        <Select.Option value="1">入金</Select.Option>
                        <Select.Option value="2">出金</Select.Option>
                        <Select.Option value="3">内转</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="数额 ($)"
                    name="money"
                    rules={[{ required: true, message: '请输入数额' }]}
                >
                    <InputNumber min={0} placeholder="请输入数额" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="开户平台"
                    name="platform"
                    rules={[{ required: true, message: '请选择开户平台' }]}
                >
                    <Select labelInValue placeholder="请选择开户平台" onChange={ changePlatform }>
                    {
                        props.platformList.map(item => (
                            <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>
                        ))
                    }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="账号"
                    name="accountItem"
                    rules={[{ required: true, message: '请选择账号' }]}
                >
                    <Select
                        labelInValue
                        placeholder="请选择账号"
                        onChange={ changeAccount }
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                    {
                        accountList.map(item => (
                            <Select.Option value={ item.id } key={ item.id }>{`${item.account} (${item.clientName})`}</Select.Option>
                        ))
                    }
                    </Select>
                </Form.Item>
                {
                    typeCode === '3' &&
                    <Form.Item
                        label="内转目标账号"
                        name="accountTargetItem"
                        rules={[{ required: true, message: '请选择内转目标账号' }]}
                    >
                        <Select
                            labelInValue
                            placeholder="请选择内转目标账号"
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                        {
                            accountTargetList.map(item => (
                                <Select.Option value={ item.id } key={ item.id }>{`${item.account} (${item.clientName})`}</Select.Option>
                            ))
                        }
                        </Select>
                    </Form.Item>
                }
            </Form>
        </Modal>
    )
}
export default ModalForm