import { useAntdTable } from 'ahooks';

const AntdTable = (service, options) => {
    const { tableProps, search, loading, run, refresh, mutate } = useAntdTable(service, options)

    const clear = () => {
        tableProps.dataSource = []
        mutate(tableProps)
    }
    const submitByParams = params => {
        run({ current: 1, pageSize: tableProps.pagination.pageSize }, params)
    }

    search['reload'] = refresh;
    search['clear'] = clear;
    search['submitByParams'] = submitByParams;
    
    tableProps.pagination.showSizeChanger = false;
    tableProps.pagination.hideOnSinglePage = true;
    tableProps.pagination.showTotal = total => `总计：${total}`;

    return { tableProps, search, loading }
}

export default AntdTable