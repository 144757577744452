import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, Row, Col, Table, Form, Input, Select, Button, Modal, message, Tooltip } from 'antd';
import { PlusOutlined, EditTwoTone, DeleteTwoTone, ProfileTwoTone, IdcardTwoTone, BankTwoTone, WalletTwoTone, FundTwoTone } from '@ant-design/icons';
import http from '@/utils/http';
import useAntdTable from '@/assets/component/AntdTable';

import ClientForm from './ClientForm';
import Detail from './Detail';
import Account from './Account';
import ClientFiles from './ClientFiles';
import BankInfo from './BankInfo';

const  ClientList = () => {
    const isAdmin = sessionStorage.isAdmin === '1';
    const userInfo = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : {};
    const history = useHistory();

    const [searchForm] = Form.useForm();
    const [levelList, setLevelList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [clientId, setClientId] = useState('');
    const [formVisible, setFormVisible] = useState(false);
    const [detailVisible, setDetailVisible] = useState(false);
    const [accountVisible, setAccountVisible] = useState(false);
    const [fileVisible, setFileVisible] = useState(false);
    const [bankVisible, setBankVisible] = useState(false);

    useEffect(() => {
        getLevels()
        getRegionList(0);
    }, []) // eslint-disable-line

    const getLevels = () => {
        http.post('/api/level/listAll').then(res => {
            setLevelList(res)
        })
    }
    const getTableData = ({current, pageSize}, searchData) => {
        let data = {
            ...searchData,
            currentPage: current,
            pageSize,
            parentId: !isAdmin ? userInfo.clientId : undefined
        }

        return http.post('/api/client/list', data).then(res => ({
            list: res.list,
            total: res.total
        }))
    }
    const { tableProps, search } = useAntdTable(getTableData, {
        defaultPageSize: 10,
        form: isAdmin ? searchForm : undefined
    });

    const { submit, reset, reload } = search;

    let listColumns = [
        { title: '姓名', dataIndex: 'name', align: 'center',
            render: (text, record) => isAdmin ? <div className="list-actions"><span onClick={ detail.bind(this, record.id) }>{ text }</span></div> : text
        },
        {
            title: '性别', dataIndex: 'sex', align: 'center',
            render: text => text === '1' ? '男' : '女'
        },
        { title: '手机号码', dataIndex: 'telephone', align: 'center' },
        { title: '身份证号', dataIndex: 'idCard', align: 'center' },
        { title: '省份', dataIndex: 'provinceName', align: 'center' },
        { title: '城市', dataIndex: 'cityName', align: 'center' }
    ]
    if(isAdmin) {
        listColumns = listColumns.concat([
            { title: '用户类型', dataIndex: 'levelName', align: 'center' },
            { title: '分润比例', dataIndex: 'profitRatio', align: 'center', render: text => `${text}%` },
            { title: '提成比例', dataIndex: 'tcRatio', align: 'center', render: text => `${text}%` },
            { title: '分佣金额', dataIndex: 'commissionMoney', align: 'center', render: text => `${text} $` },
            { title: '上级代理', dataIndex: 'parentName', align: 'center' },
            {
                title: '操作', key: 'action', align: 'center', width: 200,
                render: (text, record) => (
                    <div className="list-actions">
                        <Tooltip title="查看详情">
                            <ProfileTwoTone onClick={ detail.bind(this, record.id)} />
                        </Tooltip>
                        <Tooltip title="修改">
                            <EditTwoTone onClick={ edit.bind(this, record)} />
                        </Tooltip>
                        <Tooltip title="删除">
                            <DeleteTwoTone onClick={ del.bind(this, record.id)} />
                        </Tooltip>
                        <Tooltip title="证件信息">
                            <IdcardTwoTone onClick={ showFile.bind(this, record.id)} />
                        </Tooltip>
                        <Tooltip title="银行信息">
                            <BankTwoTone onClick={ showBankInfo.bind(this, record.id)} />
                        </Tooltip>
                        <Tooltip title="账户信息">
                            <WalletTwoTone onClick={ showAccount.bind(this, record.id)} />
                        </Tooltip>
                        <Tooltip title="收支统计">
                            <FundTwoTone onClick={ checkStat.bind(this, record.id)} />
                        </Tooltip>
                    </div>
                )
            }
        ])
    }

    const changeRegion = pid => {
        let getType = 'city';
        searchForm.setFieldsValue({ cityId: undefined })
        if(!pid){
            return
        }
        getRegionList(pid, getType)
    }
    const getRegionList = (pid, getType) => {
        http.post('/api/util/regionList', { pid }).then(res => {
            let list = res.filter(item => item.id !== 0)
            if(getType === 'city'){
                setCityList(list)
            }else {
                setProvinceList(list)
            }
        })
    }

    const detail = id => {
        setDetailVisible(true)
        setClientId(id)
    }
    const cancelDetail = () => {
        setDetailVisible(false)
        setClientId('')
    }

    const create = () => {
        setFormVisible(true);
        setClientId('')
    }
    const edit = item => {
        setFormVisible(true);
        setClientId(item.id)
    }
    const cancelForm = () => {
        setFormVisible(false)
    }
    const queryForm = values => {
        let url = '';
        let data = values;
        if(clientId) {
            url = '/api/client/update'
            data['id'] = clientId
        } else {
            url = '/api/client/add'
        }
        http.post(url, data, { isLoading: true }).then(res => {
            message.success((clientId ? '修改' : '新增') + '成功！')
            cancelForm()
            clientId ? reload() : submit()
        })
    }

    const del = id => {
        Modal.confirm({
            title: '提示',
            content: '是否确认要删除？',
            onOk: () => {
                http.post('/api/client/delete', { id }).then(res => {
                    message.success('删除成功！')
                    submit()
                })
            }
        })
    }
    // 查看账户
    const showAccount = id => {
        setAccountVisible(true);
        setClientId(id)
    }
    const cancelAccount = () => {
        setAccountVisible(false);
        setClientId('')
    }
    // 查看证件
    const showFile = id => {
        setFileVisible(true);
        setClientId(id)
    }
    const cancelFile = () => {
        setFileVisible(false);
        setClientId('')
    }
    // 查看银行信息
    const showBankInfo = id => {
        setBankVisible(true);
        setClientId(id)
    }
    const cancelBank = () => {
        setBankVisible(false);
        setClientId('')
    }
    const checkStat = id => {
        history.push('/statistics/client/' + id)
    }
    return (
        <>
            <div className="content" style={{ display: detailVisible || formVisible ? 'none' : 'block' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/home">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>客户列表</Breadcrumb.Item>
                </Breadcrumb>
                {
                    isAdmin &&
                    <Form form={ searchForm } labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} autoComplete="off">
                        <Row type="flex" align="center" className="table-search">
                            <Col span={8}>
                                <Form.Item label="姓名" name="name">
                                    <Input placeholder="请输入姓名" autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="手机号码" name="telephone">
                                    <Input placeholder="请输入手机号码" autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="用户类型" name="levelId">
                                    <Select allowClear placeholder="请选择用户类型">
                                    {
                                        levelList.map(item => (
                                            <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>
                                        ))
                                    }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="省份" name="provinceId">
                                    <Select allowClear placeholder="请选择省份" onChange={ changeRegion }>
                                    {
                                        provinceList.map(item => <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>)
                                    }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="城市" name="cityId">
                                    <Select allowClear placeholder="请选择城市">
                                    {
                                        cityList.map(item => <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>)
                                    }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} className="search-btns">
                                <Button type="primary" onClick={ submit }>查询</Button>
                                <Button type="ghost" onClick={ reset }>重置</Button>
                                <Button type="primary" onClick={ create }><PlusOutlined /> 新增</Button>
                            </Col>
                        </Row>
                    </Form>
                }
                <Table bordered size="small" rowKey="id" columns={ listColumns } {...tableProps} className="mini-table" />
                <Account
                    visible={ accountVisible }
                    clientId={ clientId }
                    onCancel={ cancelAccount }
                />
                <ClientFiles
                    visible={ fileVisible }
                    clientId={ clientId }
                    onCancel={ cancelFile }
                />
                <BankInfo
                    visible={ bankVisible }
                    clientId={ clientId }
                    onCancel={ cancelBank }
                />
            </div>
            {
                formVisible &&
                <ClientForm
                    id={ clientId }
                    onOk={ queryForm }
                    onCancel={ cancelForm }
                />
            }
            {
                detailVisible &&
                <Detail
                    id={ clientId }
                    onCancel={ cancelDetail }
                />
            }
        </>
    )
}
export default ClientList;