import { useState } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Row, Col, Table, Button, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useAntdTable from '@/assets/component/AntdTable';
import moment from 'moment';

import http from '@/utils/http';
import ModalForm from './ModalForm';
import DetailList from './Detail';

const Profit = () => {
    const isAdmin = sessionStorage.isAdmin === '1';
    const [visible, setVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [editItem, setEditItem] = useState({});

    const [detailVisible, setDetailVisible] = useState(false);
    const [detailItem, setDetailItem] = useState({});

    const getTableData = ({current, pageSize}) => {
        let data = {
            currentPage: current,
            pageSize
        }
        return http.post('/api/profit/groupList', data).then(res => ({
            list: res.list,
            total: res.total
        }))
    }
    const { tableProps, search } = useAntdTable(getTableData, {
        defaultPageSize: 10
    });

    let listColumns = [
        { title: '时间范围', dataIndex: 'title', align: 'center', width: '40%',
            render: (text, record) => <p><strong>{ moment(record.beginTime).format('YYYY-MM-DD') }</strong> 至 <strong>{ moment(record.endTime).format('YYYY-MM-DD') }</strong></p>
        },
        { title: '备注', dataIndex: 'remark', align: 'center' },
        {
            title: '操作', align: 'center', width: 160,
            render: record => (
                <div className="table-actions">
                    {
                        isAdmin &&
                        <>
                            <Button size="small" type="link" onClick={ edit.bind(this, record) }>编辑</Button>
                            <Button size="small" type="link" onClick={ delItem.bind(this, record.id) }>删除</Button>
                        </>
                    }
                    <Button size="small" type="link" onClick={ showDetail.bind(this, record) }>查看</Button>
                </div>
            )
        }
    ]
    tableProps.onRow = record => {
        return {
            onDoubleClick: () => {
                showDetail(record)
            }
        }
    }

    const create = () => {
        setVisible(true)
        setModalTitle('新增')
        setEditItem({})
    }
    const edit = record => {
        setVisible(true)
        setModalTitle('编辑')
        setEditItem(record)
    }
    const onQuery = values => {
        let url = ''
        let data = values
        
        if (editItem.id) {
            url = '/api/profit/groupUpdate'
            data['id'] = editItem.id
        } else {
            url = '/api/profit/groupAdd'
        }

        http.post(url, data, { isLoading: true }).then(res => {
            message.success(modalTitle + '成功！')
            search.submit()
            onCancel()
        })
    }
    const onCancel = () => {
        setVisible(false)
    }
    const delItem = id => {
        Modal.confirm({
            title: '提示',
            content: '是否确认要删除？',
            onOk: () => {
                http.post('/api/profit/groupDelete', { id }).then(() => {
                    message.success('删除成功！')
                    search.submit()
                })
            }
        })
    }
    const showDetail = item => {
        setDetailItem(item)
        setDetailVisible(true)
    }
    const cancelDetail = () => {
        setDetailItem({})
        setDetailVisible(false)
    }
    return (
        <>
            <div className="content" style={{ display: detailVisible ? 'none' : 'block' }}>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/home">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>分润记录</Breadcrumb.Item>
                </Breadcrumb>
                {
                    isAdmin &&
                    <Row justify="space-between" className="table-title">
                        <Col></Col>
                        <Col>
                            <Button type="primary" onClick={ create }><PlusOutlined /> 新增</Button>
                        </Col>
                    </Row>
                }
                <Table bordered size="small" rowKey="id" columns={ listColumns } {...tableProps} />
                <ModalForm
                    visible={ visible }
                    title={ modalTitle }
                    detail={ editItem }
                    onOk={ onQuery }
                    onCancel={ onCancel }
                />
            </div>
            {
                detailVisible &&
                <DetailList
                    record={ detailItem }
                    onCancel={ cancelDetail }
                />
            }
        </>
    )
}
export default Profit;