import { useState, useEffect } from 'react';
import { Modal, Tree } from 'antd';
import http from '@/utils/http';

const ModalForm = props => {
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [halfCheckedKeys, setHalfCheckedKeys] = useState([]);
    const [menuTree, setMenuTree] = useState([]);

    useEffect(() => {
        getMenuList()
    }, []) // eslint-disable-line

    useEffect(() => {
        if(!props.visible){
            return
        }
        // 删除半选中的父节点
        let menuIds = props.record.menuIds ? props.record.menuIds.split(',') : [];
        let halfCheckMenuIds = props.record.halfCheckMenuIds ? props.record.halfCheckMenuIds.split(',') : [];
        halfCheckMenuIds.forEach(item => {
            menuIds.splice(menuIds.indexOf(item), 1);
        })
        setCheckedKeys(menuIds)
        setExpandedKeys([])
    }, [props.visible]) // eslint-disable-line

    const getMenuList = () => {
        http.post('/api/menu/list').then(res => {
            let mapJson = {}
            res.forEach(item => {
                mapJson[item.id] = item
            })

            // 生成树形菜单结构
            let result = []
            res.forEach(item => {
                item.key = item.id;
                item.title = item.name;

                let parent = mapJson[item.parentId]
                if(parent){
                    (parent.children || (parent.children = [])).push(item)
                }else{
                    result.push(item)
                }
            })
            setMenuTree(result)
        })
    }

    const onCheck = (keys, e) => {
        setCheckedKeys(keys)
        setHalfCheckedKeys(e.halfCheckedKeys) // 半选中状态的父节点key
    }

    const queryForm = () => {
        let checks = checkedKeys.concat(halfCheckedKeys)
        let data = {
            menuIds: checks.join(','),
            halfCheckMenuIds: halfCheckedKeys.join(',')
        }
        props.onOk(data)
    }
    const cancel = () => {
        props.onCancel()
    }
    const onExpand = keys => {
        setExpandedKeys(keys)
    }
    return (
        <Modal
            title="配置菜单"
            centered
            visible={ props.visible }
            onOk={ queryForm }
            onCancel={ cancel }
            forceRender
            bodyStyle={{ height: 300, overflowY: 'auto' }}
        >
            <Tree
                checkable
                checkedKeys={ checkedKeys }
                expandedKeys={ expandedKeys }
                selectable={ false }
                onExpand={ onExpand }
                onCheck={ onCheck }
                treeData={ menuTree }
            />
        </Modal>
    )
}
export default ModalForm