import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Statistic } from 'antd';
import http from '@/utils/http';
import EchartsPie from './EchartsPie';
import ClientDetail from '@/pages/client/list/Detail';

import '@/assets/font/iconfont.css'

const Home = () => {
    const history = useHistory();
    const isAdmin = sessionStorage.isAdmin === '1';
    const userInfo = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : {};
    const [detail, setDetail] = useState({});
    const [clientList, setClientList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [profitList, setProfitList] = useState([]);
    const [numByPlatform, setNumByPlatform] = useState([]);
    const [numByFinancialType, setNumByFinancialType] = useState([]);
    const [byPlatform, setByPlatform] = useState([]);
    const [byFinancialType, setByFinancialType] = useState([]);

    useEffect(() => {
        if(isAdmin){
            getDetail()
        }
        console.log(isAdmin)
    }, []) // eslint-disable-line

    useEffect(() => {
        initAccountPie()
    }, [accountList]) // eslint-disable-line

    useEffect(() => {
        initProfitPie()
    }, [profitList]) // eslint-disable-line

    const getDetail = () => {
        http.post('/api/statistics/getCompanyStat', {}, { isLoading: true }).then(res => {
            setDetail(res)
            setClientList(res.clientList)
            setAccountList(res.accountList)
            setProfitList(res.profitList)
        })
    }

    const initAccountPie = () => {
        let platformNames = []
        accountList.forEach(item => {
            if(platformNames.indexOf(item.platformName) === -1){
                platformNames.push(item.platformName)
            }
        })
        let numByPlatform = []
        platformNames.forEach(name => {
            let num = 0
            accountList.forEach(item => {
                if(name === item.platformName){
                    num ++
                }
            })
            numByPlatform.push({
                name,
                value: num
            })
        })

        let financialTypeNames = []
        accountList.forEach(item => {
            if(financialTypeNames.indexOf(item.financialTypeName) === -1){
                financialTypeNames.push(item.financialTypeName)
            }
        })
        let numByFinancialType = []
        financialTypeNames.forEach(name => {
            let num = 0
            accountList.forEach(item => {
                if(name === item.financialTypeName){
                    num ++
                }
            })
            numByFinancialType.push({
                name,
                value: num
            })
        })
        setNumByPlatform(numByPlatform);
        setNumByFinancialType(numByFinancialType);
    }

    const initProfitPie = () => {
        let platformNames = []
        profitList.forEach(item => {
            if(platformNames.indexOf(item.platformName) === -1){
                platformNames.push(item.platformName)
            }
        })
        let byPlatform = []
        platformNames.forEach(name => {
            let value = 0
            profitList.forEach(item => {
                if(name === item.platformName){
                    value += Number(item.companyMoney)
                }
            })
            byPlatform.push({
                name,
                value
            })
        })

        let financialTypeNames = []
        profitList.forEach(item => {
            if(financialTypeNames.indexOf(item.financialTypeName) === -1){
                financialTypeNames.push(item.financialTypeName)
            }
        })
        let byFinancialType = []
        financialTypeNames.forEach(name => {
            let value = 0
            profitList.forEach(item => {
                if(name === item.financialTypeName){
                    value += Number(item.companyMoney)
                }
            })
            byFinancialType.push({
                name,
                value
            })
        })

        setByPlatform(byPlatform);
        setByFinancialType(byFinancialType);
    }

    const goFund = () => {
        history.push('/fund')
    }
    const goProfit = () => {
        history.push('/profit')
    }
    const goClient = () => {
        history.push('/client/list')
    }
    const goAccount = () => {
        history.push('/account')
    }

    return (
        !isAdmin ?
        <ClientDetail id={ userInfo.clientId } singlePage={ true } /> :
        <div>
            <Row gutter={ 20 }>
                <Col flex="1">
                    <Card title="当前总金量" extra={ <div className="list-actions"><span onClick={ goFund }>查看记录</span></div> }>
                        <Row type="flex" align="center" justify="space-between">
                            <Row type="flex" align="center" className="stat-icon" style={{ backgroundColor: '#3eaf07' }}>
                                <i className="iconfont icon-fund" />
                            </Row>
                            <Statistic title="USD" value={ detail.totalMoney } precision={2} />
                        </Row>
                    </Card>
                </Col>
                <Col flex="1">
                    <Card title="总分润" extra={ <div className="list-actions"><span onClick={ goProfit }>查看记录</span></div> }>
                        <Row type="flex" align="center" justify="space-between">
                            <Row type="flex" align="center" className="stat-icon" style={{ backgroundColor: '#faad14' }}>
                                <i className="iconfont icon-profit" />
                            </Row>
                            <Statistic title="CNY" value={ detail.totalProfit } precision={2} />
                        </Row>
                    </Card>
                </Col>
                <Col flex="1">
                    <Card title="客户量" extra={ <div className="list-actions"><span onClick={ goClient }>查看记录</span></div> }>
                        <Row type="flex" align="center" justify="space-between">
                            <Row type="flex" align="center" className="stat-icon" style={{ backgroundColor: '#096dd9' }}>
                                <i className="iconfont icon-client" />
                            </Row>
                            <Statistic title="人数" value={ clientList.length } />
                        </Row>
                    </Card>
                </Col>
                <Col flex="1">
                    <Card title="账户量" extra={ <div className="list-actions"><span onClick={ goAccount }>查看记录</span></div> }>
                        <Row type="flex" align="center" justify="space-between">
                            <Row type="flex" align="center" className="stat-icon" style={{ backgroundColor: '#a26e07' }}>
                                <i className="iconfont icon-account" />
                            </Row>
                            <Statistic title="数量" value={ accountList.length } />
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Card title="账户统计" style={{ marginTop: 20 }}>
                <Row>
                    <Col span={12}>
                        <div className="detail-title">按平台统计数量</div>
                        <EchartsPie
                            id="accountPie1"
                            title="按平台统计数量"
                            height={ 200 }
                            data={ numByPlatform }
                        />
                    </Col>
                    <Col span={12}>
                        <div className="detail-title">按理财种类统计数量</div>
                        <EchartsPie
                            color={['#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']}
                            id="accountPie2"
                            title="按理财种类统计数量"
                            height={ 200 }
                            data={ numByFinancialType }
                        />
                    </Col>
                </Row>
            </Card>
            <Card title="分润统计" style={{ marginTop: 20 }}>
                <Row>
                    <Col span={12}>
                        <div className="detail-title">按平台统计</div>
                        <EchartsPie
                            id="profitPie1"
                            title="按平台统计"
                            height={ 200 }
                            data={ byPlatform }
                        />
                    </Col>
                    <Col span={12}>
                        <div className="detail-title">按理财种类统计</div>
                        <EchartsPie
                            color={['#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']}
                            id="profitPie2"
                            title="按理财种类统计"
                            height={ 200 }
                            data={ byFinancialType }
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    )
}
export default Home;