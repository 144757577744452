import { useEffect } from 'react';
import { Modal, Form, DatePicker, Input } from 'antd';

import moment from 'moment';

const ModalForm = props => {
    const [ form ] = Form.useForm();

    useEffect(() => {
        if(!props.visible){
            return
        }
        form.resetFields();
        if(props.detail.id) {
            form.setFieldsValue(props.detail);
            form.setFieldsValue({
                range: [moment(props.detail.startTime), moment(props.detail.endTime)]
            })
        }
    }, [props.visible]) // eslint-disable-line

    const queryForm = () => {
        form.validateFields().then(values => {
            values.startTime = moment(values.range[0]).format('YYYY-MM-DD');
            values.endTime = moment(values.range[1]).format('YYYY-MM-DD');
            values.dateRange = `${values.startTime}_${values.endTime}`;
            delete values.range;

            props.onOk(values)
        })
    }
    const cancel = () => {
        props.onCancel()
    }
    return (
        <Modal
            title={ props.title }
            centered
            visible={ props.visible }
            onOk={ queryForm }
            onCancel={ cancel }
            forceRender
        >
            <Form form={ form } labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
                <Form.Item
                    label="日期范围"
                    name="range"
                    rules={[{ required: true, message: '请选择日期范围' }]}
                >
                    <DatePicker.RangePicker style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="备注"
                    name="remark"
                >
                    <Input.TextArea placeholder="请输入备注" rows={3} style={{ resize: 'none' }} />
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ModalForm