import { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Radio, Select, Button, Spin } from 'antd';
import { useRequest } from 'ahooks';
import http from '@/utils/http';

const ClientForm = props => {
    const clientId = props.id;
    const pageTitle = props.id ? '修改' : '新增';
    const [editForm] = Form.useForm();
    const [record, setRecord] = useState({});
    const [clientList, setClientList] = useState([]);
    const [levelList, setLevelList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [countyList, setCountyList] = useState([]);

    useEffect(() => {
        if(clientId) {
            run()
        }
        getClientList()
        getLevels()
        getRegionList(0)
    }, [clientId]) // eslint-disable-line

    const getDetail = () => {
        return new Promise(resolve => {
            let data = {
                id: clientId
            }
            http.post('/api/client/detail', data).then(res => {
                resolve(res)
            })
        })
    }
    const { loading, run } = useRequest(getDetail, {
        manual: true,
        onSuccess: res => {
            let record = res.detail;
            record.province = { key: record.provinceId, label: record.provinceName };
            record.city = { key: record.cityId, label: record.cityName };
            record.county = record.countyId ? { key: record.countyId, label: record.countyName } : undefined;
            record.parent = record.parentId ? { key: record.parentId, label: record.parentName } : undefined;
            record.level = record.levelId ? { key: record.levelId, label: record.levelName } : undefined;

            editForm.setFieldsValue(record);
            setRecord(record);

            record.province && getRegionList(record.provinceId, 'city');
            record.city && getRegionList(record.cityId, 'county');
        }
    })

    const getClientList = () => {
        http.post('/api/client/listAll').then(res => {
            setClientList(res)
        })
    }
    const getLevels = () => {
        http.post('/api/level/listAll').then(res => {
            setLevelList(res)
        })
    }
    const changeRegion = (type, region) => {
        let pid = region ? region.key : '';
        let getType = '';
        if(type === 'province'){
            getType = 'city'
            setCountyList([])
            editForm.setFieldsValue({
                city: undefined,
                county: undefined,
                address: undefined
            })
        }
        if(type === 'city'){
            getType = 'county'
            editForm.setFieldsValue({
                county: undefined,
                address: undefined
            })
        }
        if(type === 'county'){
            editForm.setFieldsValue({
                address: undefined
            })
            return
        }
        if(!pid){
            return
        }
        getRegionList(pid, getType)
    }
    const getRegionList = (pid, getType) => {
        http.post('/api/util/regionList', { pid }).then(res => {
            let list = res.filter(item => item.id !== 0)
            if(getType === 'city'){
                setCityList(list)
            }else if(getType === 'county'){
                setCountyList(list)
            }else {
                setProvinceList(list)
            }
        })
    }

    const changeLevel = level => {
        let levelItem = levelList.filter(item => item.id === level.key)[0];
        let item = { ...record };
        item.profitRatio = levelItem.profitRatio;
        item.tcRatio = levelItem.tcRatio;
        item.commissionMoney = levelItem.commissionMoney;
        setRecord(item);
    }

    const queryForm = () => {
        editForm.validateFields().then(values => {
            if (values.province) {
                values.provinceId = values.province.key;
                values.provinceName = values.province.label;
                delete values.province;
            }
            if (values.city) {
                values.cityId = values.city.key;;
                values.cityName = values.city.label
                delete values.city;
            }
            values.countyId = values.county ? values.county.key : null;
            values.countyName = values.county ? values.county.label : null;
            delete values.county;

            values.parentId = values.parent ? values.parent.key : null;
            values.parentName = values.parent ? values.parent.label : null;
            delete values.parent;

            values.levelId = values.level ? values.level.key : null;
            values.levelName = values.level ? values.level.label : null;
            delete values.level;
            values.profitRatio = record.profitRatio;
            values.tcRatio = record.tcRatio;
            values.commissionMoney = record.commissionMoney;

            props.onOk(values);
        })
    }

    return (
        <div className="content">
            <Spin spinning={ loading }>
                <div>
                    <Button type="primary" onClick={ props.onCancel }>返回</Button>
                    <strong style={{ fontSize: 16, marginLeft: 20 }}>{ pageTitle }客户</strong>
                </div>
                <Form form={ editForm } labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
                    <Row style={{ padding: 30 }}>
                        <Col span={12}>
                            <Form.Item
                                label="姓名"
                                name="name"
                                rules={[{ required: true, message: '请输入姓名' }]}
                            >
                                <Input placeholder="请输入姓名" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="性别"
                                name="sex"
                                rules={[{ required: true, message: '请选择性别' }]}
                            >
                                <Radio.Group>
                                    <Radio value="1">男</Radio>
                                    <Radio value="2">女</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="手机号"
                                name="telephone"
                                rules={[
                                    { required: true, message: '请输入手机号' },
                                    { pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/, message: '手机号格式不正确' }
                                ]}
                            >
                                <Input placeholder="请输入手机号" maxLength="11" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="身份证号"
                                name="idCard"
                                rules={[
                                    { required: true, message: '请输入身份证号' },
                                    { pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/, message: '身份证号格式不正确' }
                                ]}
                            >
                                <Input placeholder="请输入身份证号" maxLength="18" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="电子邮箱"
                                name="email"
                                rules={[
                                    { required: true, message: '请输入电子邮箱' },
                                    { pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: '电子邮箱格式不正确' }
                                ]}
                            >
                                <Input placeholder="请输入电子邮箱" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="用户类型"
                                name="level"
                                rules={[{ required: true, message: '请选择用户类型' }]}
                            >
                                <Select labelInValue allowClear placeholder="请选择用户类型" onChange={ changeLevel }>
                                {
                                    levelList.map(item => (
                                        <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>
                                    ))
                                }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="分润比例"
                            >
                                { record.profitRatio || 0 }%
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="提成比例"
                            >
                                { record.tcRatio || 0 }%
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="分佣金额($)"
                            >
                                { record.commissionMoney || 0 }
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="上级代理"
                                name="parent"
                            >
                                <Select
                                    allowClear
                                    labelInValue
                                    placeholder="请选择上级代理"
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                {
                                    clientList.map(item => (
                                        <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>
                                    ))
                                }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="地址"
                                required
                                labelCol={{ span: 3 }}
                                wrapperCol={{ span: 21 }}
                                style={{ marginBottom: 0 }}
                            >
                                <Form.Item
                                    name="province"
                                    rules={[{ required: true, message: '请选择省份' }]}
                                    style={{ display: 'inline-block' }}
                                >
                                    <Select
                                        allowClear
                                        labelInValue
                                        placeholder="请选择省份"
                                        style={{ width: 150, marginRight: 15 }}
                                        onChange={ changeRegion.bind(this, 'province') }
                                    >
                                    {
                                        provinceList.map(item => <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>)
                                    }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="city"
                                    rules={[{ required: true, message: '请选择城市' }]}
                                    style={{ display: 'inline-block' }}
                                >
                                    <Select
                                        allowClear
                                        labelInValue
                                        placeholder="请选择城市"
                                        style={{ width: 150, marginRight: 15 }}
                                        onChange={ changeRegion.bind(this, 'city') }
                                    >
                                    {
                                        cityList.map(item => <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>)
                                    }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="county"
                                    style={{ display: 'inline-block' }}
                                >
                                    <Select
                                        allowClear
                                        labelInValue
                                        placeholder="请选择区县"
                                        style={{ width: 150, marginRight: 15 }}
                                        onChange={ changeRegion.bind(this, 'county') }
                                    >
                                    {
                                        countyList.map(item => <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>)
                                    }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="address"
                                    rules={[{ required: true, message: '请输入详细地址' }]}
                                    style={{ display: 'inline-block' }}
                                >
                                    <Input placeholder="请输入详细地址"  style={{ width: 300 }} />
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="remark"
                                labelCol={{ span: 3 }}
                                wrapperCol={{ span: 20 }}
                            >
                                <Input.TextArea placeholder="请输入备注" rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'center', marginTop: 30 }}>
                        <Button onClick={ props.onCancel } style={{ marginRight: 20 }}>返回</Button>
                        <Button type="primary" onClick={ queryForm }>保存</Button>
                    </div>
                </Form>
            </Spin>
        </div>
    )
}
export default ClientForm;
