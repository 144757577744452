import { useState, useEffect } from 'react';
import { Modal, Form, Select, InputNumber } from 'antd';
import moment from 'moment';
import http from '@/utils/http';

const ModalForm = props => {
    const [ form ] = Form.useForm();
    const [accountList, setAccountList] = useState([]);
    const [curAccount, setCurAccount] = useState({});

    useEffect(() => {
        getAccounts();
    }, []) // eslint-disable-line

    useEffect(() => {
        if(!props.visible){
            return
        }
        setCurAccount({});
        form.resetFields();
        if(props.detail.id) {
            props.detail.accountItem = { key: props.detail.accountId, label: `${props.detail.account} (${props.detail.clientName})` };
            props.detail.state = { key: props.detail.stateCode, label: props.detail.stateName }
            form.setFieldsValue(props.detail);

            let account = accountList.filter(ac => ac.id === props.detail.accountId)[0]
            setCurAccount(account);
        }
    }, [props.visible]) // eslint-disable-line

    const getAccounts = () => {
        http.post('/api/account/list').then(res => {
            setAccountList(res)
        })
    }
    const changeAccount = item => {
        let account = accountList.filter(ac => ac.id === item.key)[0]
        form.setFieldsValue({
            profitRatio: account.profitRatio
        })
        setCurAccount(account)
    }

    useEffect(() => {
        countMoney()
    }, [curAccount]) // eslint-disable-line

    const countMoney = () => {
        let account = { ...curAccount };
        if(!account.id){
            return
        }
        let money = form.getFieldValue('money') || 0;
        let profitRatio =  form.getFieldValue('profitRatio') || 0;
        let clientMoney = (money * (profitRatio / 100)).toFixed(2) || 0;
        let agentMoney = account.tcRatio ? (money * (account.tcRatio / 100)).toFixed(2) : 0;
        let companyMoney = (money - clientMoney - agentMoney).toFixed(2) || 0;

        form.setFieldsValue({
            clientMoney,
            agentMoney,
            companyMoney
        })
    }

    const changeHands = () => {
        let account = { ...curAccount };
        if(!account.id){
            return
        }
        let hands = form.getFieldValue('tradeHands') || 0;
        let commission = account.commissionMoney;
        let agentCommission = (hands * commission).toFixed(2);
        form.setFieldsValue({
            agentCommission
        })
    }

    const queryForm = () => {
        form.validateFields().then(values => {
            values.accountId = values.accountItem.key;
            values.account = values.accountItem.label.substring(0, values.accountItem.label.indexOf(' ('));
            values.stateCode = values.state.key;
            values.stateName = values.state.label;
            delete values.accountItem;
            delete values.state;
            values.operationDate = moment(values.operationDate).format('YYYY-MM-DD');
            values.firstMoney = curAccount.firstMoney;
            values.financialTypeCode = curAccount.financialTypeCode;
            values.financialTypeName = curAccount.financialTypeName;
            values.platformCode = curAccount.platformCode;
            values.platformName = curAccount.platformName;

            props.onOk(values)
        })
    }
    const cancel = () => {
        props.onCancel()
    }
    return (
        <Modal
            title={ props.title }
            width="700px"
            centered
            visible={ props.visible }
            onOk={ queryForm }
            onCancel={ cancel }
            forceRender
        >
            <Form form={ form } labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
                <Form.Item
                    label="账号"
                    name="accountItem"
                    rules={[{ required: true, message: '请选择账号' }]}
                >
                    <Select
                        labelInValue
                        placeholder="请选择账号"
                        onChange={ changeAccount }
                        disabled={ props.detail.id }
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                    {
                        accountList.map(item => (
                            <Select.Option value={ item.id } key={ item.id }>{`${item.account} (${item.clientName})`}</Select.Option>
                        ))
                    }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="当日汇率"
                    name="exchangeRatio"
                    rules={[{ required: true, message: '请输入当日汇率' }]}
                    initialValue={ 6.4 }
                >
                    <InputNumber min={0} placeholder="请输入当日汇率" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="分润比例"
                    name="profitRatio"
                >
                    <InputNumber min={0} formatter={ text => `${text}%` } placeholder="请输入分润比例" onChange={ countMoney } style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="收益数额(USD)"
                    name="money"
                    rules={[{ required: true, message: '请输入收益数额' }]}
                >
                    <InputNumber min={0} placeholder="请输入收益数额" style={{ width: '100%' }} onChange={ countMoney } />
                </Form.Item>
                <Form.Item
                    label="客户分润(USD)"
                    name="clientMoney"
                    rules={[{ required: true, message: '请输入客户分润' }]}
                >
                    <InputNumber min={0} placeholder="请输入客户分润" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="公司分润(USD)"
                    name="companyMoney"
                    rules={[{ required: true, message: '请输入公司分润' }]}
                >
                    <InputNumber min={0} placeholder="请输入公司分润" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="代理分润(USD)"
                    name="agentMoney"
                    rules={[{ required: true, message: '请输入代理所得' }]}
                    initialValue={0}
                >
                    <InputNumber min={0} placeholder="请输入代理所得" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="交易手数"
                    name="tradeHands"
                    rules={[{ required: true, message: '请输入交易手数' }]}
                    initialValue={0}
                >
                    <InputNumber min={0} placeholder="请输入交易手数" onChange={ changeHands } style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="代理分佣(USD)"
                    name="agentCommission"
                    rules={[{ required: true, message: '请输入代理分佣' }]}
                    initialValue={0}
                >
                    <InputNumber min={0} placeholder="请输入代理分佣" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="完成分润"
                    name="state"
                    rules={[{ required: true, message: '请选择是否完成分润' }]}
                >
                    <Select labelInValue placeholder="请选择是否完成分润">
                        <Select.Option value="1">是</Select.Option>
                        <Select.Option value="0">否</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ModalForm