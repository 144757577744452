import { useState, useEffect } from 'react';
import { Row, Col, Statistic, Radio, Table, Form, Input, Select, Button, Spin } from 'antd';
import useAntdTable from '@/assets/component/AntdTable';
import { useRequest } from 'ahooks';
import http from '@/utils/http';
import '../../style.less';

const ProfitAgent = props => {
    const isAdmin = sessionStorage.isAdmin === '1';
    const userInfo = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : {};
    const [searchForm] = Form.useForm();
    const [agentList, setAgentList] = useState([]);
    const [curAgentId, setCurAgentId] = useState('');
    const [totalData, setTotalData] = useState({});

    useEffect(() => {
        if(isAdmin) {
            getAgentList()
        }else {
            setCurAgentId(userInfo.clientId)
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if(!curAgentId) {
            return
        }
        submit()
        getTotalReq.run()
    }, [curAgentId]) // eslint-disable-line

    const getAgentList = () => {
        let data = {
            groupId: props.groupId
        }
        http.post('/api/profit/agentList', data).then(res => {
            let array = [];
            for(let key in res) {
                array.push({
                    id: key,
                    name: res[key]
                })
            }
            setAgentList(array)
            array.length > 0 && setCurAgentId(array[0].id)
        })
    }

    const changeAgent = e => {
        setCurAgentId(e.target.value)
    }

    const getTotalData = () => {
        return new Promise(resolve => {
            let data = {
                groupId: props.groupId,
                parentId: curAgentId
            }
            http.post('/api/profit/detailStat', data).then(res => {
                resolve(res)
            })
        })
    }
    const getTotalReq = useRequest(getTotalData, {
        onSuccess: res => {
            setTotalData(res)
        }
    })

    const getTableData = ({current, pageSize}, searchData) => {
        let data = {
            ...searchData,
            currentPage: current,
            pageSize,
            groupId: props.groupId,
            parentId: curAgentId
        }
        return http.post('/api/profit/detailList', data).then(res => ({
            list: res.list,
            total: res.total
        }))
    }
    const { tableProps, search } = useAntdTable(getTableData, {
        manual: true,
        defaultPageSize: 10,
        form: searchForm
    });
    const { submit, reset } = search;

    let listColumns = isAdmin ? [
        { title: '客户姓名', dataIndex: 'clientName', align: 'center' },
        { title: '账号', dataIndex: 'account', align: 'center' },
        { title: '入金量(USD)', dataIndex: 'firstMoney', align: 'center' },
        { title: '收益(USD)', dataIndex: 'money', align: 'center' },
        { title: '客户分润(USD)', dataIndex: 'clientMoney', align: 'center' },
        { title: '公司分润(USD)', dataIndex: 'companyMoney', align: 'center' },
        { title: '当日汇率', dataIndex: 'exchangeRatio', align: 'center' },
        { title: '客户分润(CNY)', dataIndex: 'clientMoney', align: 'center', render: (text, record) => `${(text * record.exchangeRatio).toFixed(2)}` },
        { title: '公司分润(CNY)', dataIndex: 'companyMoney', align: 'center', render: (text, record) => `${(text * record.exchangeRatio).toFixed(2)}` },
        { title: '代理', dataIndex: 'parentName', align: 'center' },
        { title: '代理分润(USD)', dataIndex: 'agentMoney', align: 'center' },
        { title: '交易手数', dataIndex: 'tradeHands', align: 'center' },
        { title: '代理分佣(USD)', dataIndex: 'agentCommission', align: 'center' },
        { title: '完成分润', dataIndex: 'stateName', align: 'center', render: (text, record) => <strong className={ record.stateCode === '1' ? 'txt-green' : ''}>{ text }</strong> }
    ] : [
        { title: '客户姓名', dataIndex: 'clientName', align: 'center' },
        { title: '账号', dataIndex: 'account', align: 'center' },
        { title: '入金量(USD)', dataIndex: 'firstMoney', align: 'center' },
        { title: '收益(USD)', dataIndex: 'money', align: 'center' },
        { title: '当日汇率', dataIndex: 'exchangeRatio', align: 'center' },
        { title: '代理', dataIndex: 'parentName', align: 'center' },
        { title: '代理分润(USD)', dataIndex: 'agentMoney', align: 'center' },
        { title: '交易手数', dataIndex: 'tradeHands', align: 'center' },
        { title: '代理分佣(USD)', dataIndex: 'agentCommission', align: 'center' }
    ]
    return (
        <div className="content">
            <div style={{ marginBottom: 15 }}>
                <Button type="primary" onClick={ props.onCancel }>返回</Button>
                <strong style={{ marginLeft: 15, fontSize: 16 }}>
                    代理分润
                </strong>
            </div>
            {
                isAdmin &&
                <Radio.Group value={ curAgentId } onChange={ changeAgent } style={{ width: '100%', marginBottom: 15 }}>
                    <Row>
                    {
                        agentList.map(item => (
                            <Col span={3} key={ item.id }><Radio value={ item.id } >{ item.name }</Radio></Col>
                        ))
                    }
                    </Row>
                </Radio.Group>
            }
            <Spin spinning={ getTotalReq.loading }>
                <Row gutter={20} style={{ marginBottom: 20 }}>
                    <Col style={{ width: '20%' }}>
                        <div className="profit-total" style={{ backgroundColor: '#6bb7fd' }}>
                            <strong>总收益</strong>
                            <Row justify="space-between" align="middle">
                                <span>USD</span>
                                <Statistic value={ totalData.totalMoney } precision={2} />
                            </Row>
                            <Row justify="space-between" align="middle">
                                <span>CNY</span>
                                <Statistic value={ totalData.totalMoney * 6.4 } precision={2} />
                            </Row>
                        </div>
                    </Col>
                    <Col style={{ width: '20%' }}>
                        <div className="profit-total" style={{ backgroundColor: '#f7a327' }}>
                            <strong>代理总分润</strong>
                            <Row justify="space-between" align="middle">
                                <span>USD</span>
                                <Statistic value={ totalData.totalAgentMoney } precision={2} />
                            </Row>
                            <Row justify="space-between" align="middle">
                                <span>CNY</span>
                                <Statistic value={ totalData.totalAgentMoney * 6.4 } precision={2} />
                            </Row>
                        </div>
                    </Col>
                    <Col style={{ width: '20%' }}>
                        <div className="profit-total" style={{ backgroundColor: '#2db9cb' }}>
                            <strong>代理总佣金</strong>
                            <Row justify="space-between" align="middle">
                                <span>USD</span>
                                <Statistic value={ totalData.totalAgentCommission } precision={2} />
                            </Row>
                            <Row justify="space-between" align="middle">
                                <span>CNY</span>
                                <Statistic value={ totalData.totalAgentCommission * 6.4 } precision={2} />
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Spin>
            <Form form={ searchForm } labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} autoComplete="off">
                <Row type="flex" align="center" className="table-search">
                    <Col span={6}>
                        <Form.Item label="客户姓名" name="clientName">
                            <Input placeholder="请输入客户姓名" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="完成分润" name="stateCode">
                            <Select allowClear placeholder="请选择是否完成分润">
                                <Select.Option value="1">是</Select.Option>
                                <Select.Option value="0">否</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12} className="search-btns">
                        <Button type="primary" onClick={ submit }>查询</Button>
                        <Button type="ghost" onClick={ reset }>重置</Button>
                    </Col>
                </Row>
            </Form>
            <Table bordered size="small" rowKey="id" className="mini-table" columns={ listColumns } {...tableProps} />
        </div>
    )
}
export default ProfitAgent;