import { useState, useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import http from '@/utils/http';

const ModalForm = props => {
    const [ form ] = Form.useForm();
    const [roleList, setRoleList] = useState([]);
    const [clientList, setClientList] = useState([]);

    useEffect(() => {
        getRoleList()
        getClientList()
    }, []) // eslint-disable-line

    useEffect(() => {
        if(!props.visible){
            return
        }
        form.resetFields();
        form.setFieldsValue(props.detail);
        if(props.detail.roleId){
            form.setFieldsValue({
                roleItem: { key: props.detail.roleId, label: props.detail.roleName }
            })
        }
        if(props.detail.clientId){
            form.setFieldsValue({
                clientItem: { key: props.detail.clientId, label: props.detail.clientName }
            })
        }
    }, [props.visible]) // eslint-disable-line

    const getRoleList = () => {
        http.post('/api/role/listAll').then(res => {
            setRoleList(res)
        })
    }
    const getClientList = () => {
        http.post('/api/client/listAll').then(res => {
            setClientList(res)
        })
    }
    const queryForm = () => {
        form.validateFields().then(values => {
            if(values.roleItem) {
                values.roleId = values.roleItem.value;
                values.roleName = values.roleItem.label;
                delete values.roleItem
            }
            if(values.clientItem) {
                values.clientId = values.clientItem.value;
                values.clientName = values.clientItem.label;
                delete values.clientItem
            }
            props.onOk(values)
        })
    }
    const cancel = () => {
        props.onCancel()
    }
    return (
        <Modal
            title={ props.title }
            centered
            visible={ props.visible }
            onOk={ queryForm }
            onCancel={ cancel }
            forceRender
        >
            <Form form={ form } labelCol={{ span: 4 }} wrapperCol={{ span: 19 }} autoComplete="off">
                {/* 防止自动填充 */}
                <div style={{ height: 0, overflow: 'hidden', opacity: 0 }}>
                    <Input name="username" />
                    <Input name="password" type="password" />
                </div>
                {/* end */}
                <Form.Item
                    label="账号"
                    name="username"
                    rules={[{ required: true, message: '请输入账号' }]}
                >
                    <Input placeholder="请输入账号" />
                </Form.Item>
                <Form.Item
                    label="密码"
                    name="password"
                    rules={[{ required: true, message: '请输入密码' }]}
                >
                    <Input placeholder="请输入密码" />
                </Form.Item>
                <Form.Item
                    label="姓名"
                    name="name"
                    rules={[{ required: true, message: '请输入姓名' }]}
                >
                    <Input placeholder="请输入姓名" />
                </Form.Item>
                <Form.Item
                    label="角色"
                    name="roleItem"
                    rules={[{ required: true, message: '请选择角色' }]}
                >
                    <Select labelInValue placeholder="请选择角色">
                    {
                        roleList.map(item => (
                            <Select.Option value={ item.id } key={ item.id }>{ item.roleName }</Select.Option>
                        ))
                    }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="所属客户"
                    name="clientItem"
                >
                    <Select labelInValue placeholder="请选择所属客户">
                    {
                        clientList.map(item => (
                            <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>
                        ))
                    }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ModalForm