import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Row, Col, Table, Form, Input, Select, DatePicker, Button } from 'antd';
import { useRequest } from 'ahooks';
import http from '@/utils/http';
import moment from 'moment';

const AccountList = () => {
    const [searchForm] = Form.useForm();
    const [platformList, setPlatformList] = useState([]);
    const [financialTypeList, setFinancialTypeList] = useState([]);
    const [params, setParams] = useState({});
    const [list, setList] = useState([]);

    useEffect(() => {
        getPlatformList();
        getFinancialTypeList()
    }, []) // eslint-disable-line

    useEffect(() => {
        run()
    }, [params]) // eslint-disable-line

    const getPlatformList = () => {
        http.post('/api/platform/listAll').then(res => {
            setPlatformList(res)
        })
    }
    const getFinancialTypeList = () => {
        http.post('/api/financialType/listAll').then(res => {
            setFinancialTypeList(res)
        })
    }

    const getList = () => {
        return new Promise(resolve => {
            let data = { ...params }
            data['byOperationDate'] = '1'

            http.post('/api/account/list', data).then(res => {
                resolve(res)
            })
        })
    }
    const { loading, run } = useRequest(getList, {
        manual: true,
        onSuccess: res => {
            setList(res)
        }
    });
    const querySearch = () => {
        searchForm.validateFields().then(values => {
            if(values.dateRange){
                values.startDate = moment(values.dateRange[0]).format('YYYY-MM-DD')
                values.endDate = moment(values.dateRange[1]).format('YYYY-MM-DD')
                delete values.dateRange
            }
            setParams(values)
        })
    }
    const reset = () => {
        searchForm.resetFields()
        setParams({})
    }

    const listColumns = [
        { title: '注册时间', dataIndex: 'operationDate', align: 'center', render: text => moment(text).format('YYYY-MM-DD') },
        { title: '客户姓名', dataIndex: 'clientName', align: 'center' },
        { title: '开户平台', dataIndex: 'platformName', align: 'center' },
        { title: '账号', dataIndex: 'account', align: 'center' },
        { title: '账号类型', dataIndex: 'accountTypeName', align: 'center' },
        { title: '入金量', dataIndex: 'firstMoney', align: 'center', render: text => `${text} $` },
        { title: '当前金量', dataIndex: 'money', align: 'center', render: text => `${text} $` },
        { title: '总分润', dataIndex: 'totalProfitMoney', align: 'center', render: text => `${text} $` },
        { title: '理财种类', dataIndex: 'financialTypeName', align: 'center' },
        { title: '服务器', dataIndex: 'server', align: 'center' },
        { title: '操作密码', dataIndex: 'operationPassword', align: 'center' },
        { title: '观摩密码', dataIndex: 'viewPassword', align: 'center' },
        { title: '账户状态', dataIndex: 'stateName', align: 'center', render: (text, record) => <strong className={ record.stateCode === '1' ? 'txt-green' : 'txt-red' }>{ text }</strong> }
    ]
    const tableProps = {
        dataSource: list,
        loading,
        pagination: list.length < 11 ? false : true
    }

    return (
        <div className="content">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/home">首页</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>账户列表</Breadcrumb.Item>
            </Breadcrumb>
            <Form form={ searchForm } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} autoComplete="off" onFinish={ querySearch }>
                <Row type="flex" align="center" className="table-search">
                    <Col span={6}>
                        <Form.Item label="日期范围" name="dateRange">
                            <DatePicker.RangePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="开户平台" name="platformCode">
                            <Select allowClear placeholder="请选择开户平台">
                            {
                                platformList.map(item => (
                                    <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>
                                ))
                            }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="账号" name="account">
                            <Input placeholder="请输入账号" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="客户姓名" name="clientName">
                            <Input placeholder="请输入客户姓名" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="理财种类" name="financialTypeCode">
                            <Select allowClear placeholder="请选择理财种类">
                            {
                                financialTypeList.map(item => (
                                    <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>
                                ))
                            }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="账户状态" name="stateCode">
                            <Select allowClear placeholder="请选择账户状态">
                                <Select.Option value="1">启用</Select.Option>
                                <Select.Option value="0">停用</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12} className="search-btns">
                        <Button type="primary" htmlType="submit">查询</Button>
                        <Button type="ghost" onClick={ reset }>重置</Button>
                    </Col>
                </Row>
            </Form>
            <Table bordered size="small" rowKey="id" columns={ listColumns } {...tableProps} />
        </div>
    )
}

export default AccountList;