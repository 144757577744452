import { useState, useEffect } from 'react';
import { Modal, Row, Col, Descriptions, Form, Input, Select, InputNumber, DatePicker, Button, message, Spin, Empty } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import http from '@/utils/http';
import moment from 'moment';

const Account = props => {
    const [list, setList] = useState([]);

    const [modalVisible, setModalVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [editItem, setEditItem] = useState({});

    useEffect(() => {
        if(!props.visible) {
            return
        }
        setList([])
        run()
    }, [props.visible]) // eslint-disable-line

    const getList = () => {
        return new Promise(resolve => {
            let data = {
                clientId: props.clientId
            }
            http.post('/api/account/list', data).then(res => {
                resolve(res)
            })
        })
    }
    const { loading, run } = useRequest(getList, {
        manual: true,
        onSuccess: res => {
            setList(res)
        }
    })

    const del = id => {
        Modal.confirm({
            title: '提示',
            content: '是否确认要删除？',
            onOk: () => {
                http.post('/api/account/delete', { id }).then(res => {
                    message.success('删除成功！')
                    run()
                })
            }
        })
    }
    const create = () => {
        setModalVisible(true);
        setModalTitle('新增账户');
        setEditItem({})
    }
    const edit = record => {
        let item = { ...record };
        item.operationDate = moment(item.operationDate);
        item.platform = { key: item.platformCode, lebal: item.platformName };
        item.accountType = { key: item.accountTypeCode, lebal: item.accountTypeName };
        item.financialType = { key: item.financialTypeCode, lebal: item.financialTypeName };
        item.state = { key: item.stateCode, lebal: item.stateName };

        setModalVisible(true);
        setModalTitle('修改账户');
        setEditItem(item)
    }
    const cancel = () => {
        setModalVisible(false);
    }
    const queryForm = values => {
        let url = ''
        let data = { ...values }
        data.clientId = props.clientId;
        
        if (editItem.id) {
            url = '/api/account/update'
            data['id'] = editItem.id
        } else {
            url = '/api/account/add'
        }

        http.post(url, data, { isLoading: true }).then(res => {
            message.success(modalTitle + '成功！')
            run()
            cancel()
        })
    }
    
    return (
        <Modal
            title="账户信息"
            visible={ props.visible }
            width="80%"
            centered
            footer={ null }
            onCancel={ props.onCancel }
        >
            <Button type="primary" onClick={ create }><PlusOutlined /> 新增账户</Button>
            <Spin spinning={ loading }>
                <Row gutter={[20, 20]} style={{ marginTop: 15, height: 420, overflowY: 'auto' }}>
                {
                    list.map(item => (
                        <Col span={12} key={ item.id }>
                            <Descriptions title={ item.platformName } className="account-item" column={2} extra={ <div><Button type="primary" size="small" onClick={ edit.bind(this, item) }>修改</Button><Button type="danger" size="small" onClick={ del.bind(this, item.id) } style={{ marginLeft: 10 }}>删除</Button></div> }>
                                <Descriptions.Item label="开户账号">{ item.account }</Descriptions.Item>
                                <Descriptions.Item label="账户类型">{ item.accountTypeName }</Descriptions.Item>
                                <Descriptions.Item label="账户入金量">{ item.firstMoney }</Descriptions.Item>
                                <Descriptions.Item label="当前金量">{ item.money }</Descriptions.Item>
                                <Descriptions.Item label="理财种类">{ item.financialTypeName }</Descriptions.Item>
                                <Descriptions.Item label="服务器">{ item.server }</Descriptions.Item>
                                <Descriptions.Item label="操作密码">{ item.operationPassword }</Descriptions.Item>
                                <Descriptions.Item label="观摩密码">{ item.viewPassword }</Descriptions.Item>
                                <Descriptions.Item label="注册时间">{ item.operationDate }</Descriptions.Item>
                                <Descriptions.Item label="账户状态">{ item.stateName }</Descriptions.Item>
                            </Descriptions>
                        </Col>
                    ))
                }
                {
                    list.length === 0 && 
                    <Col span={24}><Empty /></Col>
                }
                </Row>
            </Spin>
            <ModalForm
                visible={ modalVisible }
                title={ modalTitle }
                record={ editItem }
                onOk={ queryForm }
                onCancel={ cancel }
            />
        </Modal>
    )
}
const ModalForm = props => {
    const [form] = Form.useForm();
    const [platformList, setPlatformList] = useState([]);
    const [financialTypeList, setFinancialTypeList] = useState([]);

    useEffect(() => {
        getPlatformList()
        getFinancialTypeList()
    }, []) // eslint-disable-line

    useEffect(() => {
        if(props.visible) {
            form.resetFields()
            form.setFieldsValue(props.record)
        }
    }, [props.visible]) // eslint-disable-line

    const getPlatformList = () => {
        http.post('/api/platform/listAll').then(res => {
            setPlatformList(res)
        })
    }
    const getFinancialTypeList = () => {
        http.post('/api/financialType/listAll').then(res => {
            setFinancialTypeList(res)
        })
    }
    const queryForm = () => {
        form.validateFields().then(values => {
            values.platformCode = values.platform.key;
            values.platformName = values.platform.label;
            values.accountTypeCode = values.accountType.key;
            values.accountTypeName = values.accountType.label;
            values.financialTypeCode = values.financialType.key;
            values.financialTypeName = values.financialType.label;
            values.stateCode = values.state.key;
            values.stateName = values.state.label;
            delete values.platform;
            delete values.accountType;
            delete values.financialType;
            delete values.state;
            values.operationDate = moment(values.operationDate).format('YYYY-MM-DD');
            
            props.onOk(values)
        })
        
    }
    return (
        <Modal
            title={ props.title }
            centered
            visible={ props.visible }
            onOk={ queryForm }
            onCancel={ props.onCancel }
        >
            <div>
                <Form form={form} onSubmit={ queryForm } labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off">
                    <Form.Item
                        label="开户平台"
                        name="platform"
                        rules={[{ required: true, message: '请输入开户平台' }]}
                    >
                        <Select labelInValue placeholder="请选择开户平台">
                        {
                            platformList.map(item => (
                                <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>
                            ))
                        }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="开户账号"
                        name="account"
                        rules={[{ required: true, message: '请输入开户账号' }]}
                    >
                        <Input placeholder="请输入开户账号" />
                    </Form.Item>
                    <Form.Item
                        label="账户类型"
                        name="accountType"
                        rules={[{ required: true, message: '请选择账户类型' }]}
                    >
                        <Select labelInValue placeholder="请选择账户类型">
                            <Select.Option value="1">标准</Select.Option>
                            <Select.Option value="2">ECN</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="账户入金量 ($)"
                        name="firstMoney"
                        rules={[{ required: true, message: '请输入账户入金量' }]}
                    >
                        <InputNumber min={0} placeholder="请输入账户入金量" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="理财种类"
                        name="financialType"
                        rules={[{ required: true, message: '请选择理财种类' }]}
                    >
                        <Select labelInValue placeholder="请选择理财种类">
                        {
                            financialTypeList.map(item => (
                                <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>
                            ))
                        }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="服务器"
                        name="server"
                        rules={[{ required: true, message: '请输入服务器' }]}
                    >
                        <Input placeholder="请输入服务器" />
                    </Form.Item>
                    <Form.Item
                        label="操作密码"
                        name="operationPassword"
                        rules={[{ required: true, message: '请输入操作密码' }]}
                    >
                        <Input placeholder="请输入操作密码" />
                    </Form.Item>
                    <Form.Item
                        label="观摩密码"
                        name="viewPassword"
                        rules={[{ required: true, message: '请输入观摩密码' }]}
                    >
                        <Input placeholder="请输入观摩密码" />
                    </Form.Item>
                    <Form.Item
                        label="注册时间"
                        name="operationDate"
                        rules={[{ required: true, message: '请选择注册时间' }]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        label="账户状态"
                        name="state"
                        rules={[{ required: true, message: '请选择账户状态' }]}
                    >
                        <Select labelInValue placeholder="请选择账户状态">
                            <Select.Option value="1">启用</Select.Option>
                            <Select.Option value="0">停用</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}
export default Account;