import { useState, useEffect } from 'react';
import { Modal, Form, Input, message, Spin } from 'antd';
import { useRequest } from 'ahooks';
import http from '@/utils/http';

const BankInfo = props => {
    const [form] = Form.useForm();
    const [info, setInfo] = useState({});

    useEffect(() => {
        if(!props.visible) {
            return
        }
        form.resetFields()
        run()
    }, [props.visible]) // eslint-disable-line

    const getList = () => {
        return new Promise(resolve => {
            let data = {
                clientId: props.clientId
            }
            http.post('/api/bankInfo/detail', data).then(res => {
                resolve(res)
            })
        })
    }
    const { loading, run } = useRequest(getList, {
        manual: true,
        onSuccess: res => {
            setInfo(res)
            form.setFieldsValue(res)
        }
    })

    const queryForm = () => {
        form.validateFields().then(values => {
            let data = { ...values };
            data.clientId = props.clientId;

            if(info.id){
                data.id = info.id
            }

            http.post('/api/bankInfo/save', data, { isLoading: true }).then(() => {
                message.success('保存银行信息成功')
                props.onCancel()
            })
        })
    }

    return (
        <Modal
            title="银行信息"
            visible={ props.visible }
            centered
            onOk={ queryForm }
            onCancel={ props.onCancel }
        >
            <Spin spinning={ loading }>
                <Form form={ form } labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} autoComplete="off" onFinish={ queryForm }>
                    <Form.Item
                        label="持有人姓名"
                        name="holderName"
                        rules={[{ required: true, message: '请输入持有人姓名' }]}
                    >
                        <Input placeholder="请输入持有人姓名" />
                    </Form.Item>
                    <Form.Item
                        label="银行名称"
                        name="bankName"
                        rules={[{ required: true, message: '请输入银行名称' }]}
                    >
                        <Input placeholder="请输入银行名称" />
                    </Form.Item>
                    <Form.Item
                        label="银行支行全称"
                        name="subBankName"
                        rules={[{ required: true, message: '请输入银行支行全称' }]}
                    >
                        <Input placeholder="请输入银行支行全称" />
                    </Form.Item>
                    <Form.Item
                        label="收款人银行账号"
                        name="bankNum"
                        rules={[{ required: true, message: '请输入收款人银行账号' }]}
                    >
                        <Input placeholder="请输入收款人银行账号" />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
}
export default BankInfo;