import { useState } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Row, Col, Button, Modal, message, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useAntdTable from '@/assets/component/AntdTable';

import http from '@/utils/http';
import ModalForm from './ModalForm';

const SysPlatform = () => {
    const [visible, setVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [editItem, setEditItem] = useState({});

    const getTableData = ({current, pageSize}) => {
        let data = {
            currentPage: current,
            pageSize
        }
        return http.post('/api/platform/list', data).then(res => ({
            list: res.list,
            total: res.total
        }))
    }
    const { tableProps, search } = useAntdTable(getTableData, {
        defaultPageSize: 10
    });

    const { submit, reload } = search;

    const listColumns = [
        { title: '平台名称', dataIndex: 'name', align: 'center' },
        {
            title: '操作', align: 'center', width: 200,
            render: record => (
                <div className="table-actions">
                    <Button size="small" type="link" onClick={ edit.bind(this, record) }>编辑</Button>
                    <Button size="small" type="link" onClick={ delItem.bind(this, record.id) }>删除</Button>
                </div>
            )
        }
    ]

    const create = () => {
        setVisible(true)
        setModalTitle('新增')
        setEditItem({})
    }
    const edit = record => {
        setVisible(true)
        setModalTitle('编辑')
        setEditItem(record)
    }
    const onQuery = values => {
        let url = '/api/platform/add'
        let data = {...values}

        if(editItem.id) {
            url = '/api/platform/update'
            data.id = editItem.id
        }

        http.post(url, data, { isLoading: true }).then(res => {
            message.success(`${modalTitle}成功`)
            editItem.id ? reload() : submit();
            onCancel()
        })
    }
    const onCancel = () => {
        setVisible(false)
    }
    // 删除
    const delItem = id => {
        Modal.confirm({
            title: '提示',
            content: '确认要删除？',
            onOk: () => {
                let data = {
                    id
                }
                http.post('/api/platform/delete', data, { isLoading: true }).then(res => {
                    message.success('删除成功！')
                    submit()
                })
            }
        })
    }
    return (
        <div className="content">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/home">首页</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>开户平台管理</Breadcrumb.Item>
            </Breadcrumb>
            <Row className="table-title" justify="space-between">
                <Col></Col>
                <Col>
                    <Button type="primary" onClick={ create }><PlusOutlined />新增</Button>
                </Col>
            </Row>
            <Table bordered size="small" rowKey="id" columns={ listColumns } {...tableProps} />
            <ModalForm
                visible={ visible }
                title={ modalTitle }
                detail={ editItem }
                onOk={ onQuery }
                onCancel={ onCancel }
            />
        </div>
    )
}
export default SysPlatform;