import { useEffect } from 'react';
import { Modal, Form, Input, TreeSelect } from 'antd';

const ModalForm = props => {
    const [ modalForm ] = Form.useForm();
    useEffect(() => {
        if(!props.visible){
            return
        }
        modalForm.resetFields();
        if(props.record.id) {
            modalForm.setFieldsValue(props.record)
        }
    }, [props.visible]) // eslint-disable-line

    const queryForm = () => {
        modalForm.validateFields().then(values => {
            props.onOk(values)
        })
    }
    const cancel = () => {
        props.onCancel()
    }
    return (
        <Modal
            title={ props.title }
            centered
            visible={ props.visible }
            onOk={ queryForm }
            onCancel={ cancel }
            forceRender
        >

            <Form form={ modalForm } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} autoComplete="off">
                <Form.Item
                    label="父菜单"
                    name="parentId"
                    rules={[{ required: true, message: '请选择父菜单' }]}
                >
                    <TreeSelect
                        placeholder="请选择父菜单"
                        treeData={ props.treeData || [] }
                        treeDataSimpleMode={true}
                    />
                </Form.Item>
                <Form.Item
                    label="菜单名称"
                    name="name"
                    rules={ [{ required: true, message: '请输入菜单名称' }] }
                >
                    <Input placeholder="请输入菜单名称" />
                </Form.Item>
                <Form.Item
                    label="菜单地址"
                    name="url"
                >
                    <Input placeholder="请输入菜单地址" />
                </Form.Item>
                <Form.Item
                    label="菜单Icon"
                    name="icon"
                >
                    <Input placeholder="请输入菜单Icon" />
                </Form.Item>
                <Form.Item
                    label="显示顺序"
                    name="sort"
                >
                    <Input placeholder="请输入显示顺序，例如：1" />
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ModalForm