import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Spin, Button, Modal, message, Tree } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import http from '@/utils/http';
import ModalForm from './ModalForm';

import './style.less'

const SysMenu = () => {
    let [isLoading, setIsLoading] = useState(false);
    let [treeData, setTreeData] = useState([]);
    let [treeMenu, setTreeMenu] = useState([]);
    let [visible, setVisible] = useState(false);
    let [modalTitle, setModalTitle] = useState('');
    let [editItem, setEditItem] = useState({});
 
    useEffect(() => {
        getMenuList()
    }, []) // eslint-disable-line

    const getMenuList = () => {
        setIsLoading(true)
        http.post('/api/menu/list').then(res => {
            let treeData = [{id: '-1', title: '根菜单', value: '-1'}]
            res.forEach(item => {
                let t = {
                    id: item.id,
                    pId: item.parentId,
                    title: item.name,
                    value: item.id
                }
                treeData.push(t)
            })
            setTreeData(treeData)
            composeTree(res)
        })
    }
    const composeTree = list => {
        let result = []
        let mapJson = {}
        list.forEach(item => {
            mapJson[item.id] = item
        })
        list.forEach(item => {
            item.key = item.id;
            item.title = item.name;

            let parent = mapJson[item.parentId]
            if(parent){
                (parent.children || (parent.children = [])).push(item)
            }else{
                result.push(item)
            }
        })
        setIsLoading(false)
        setTreeMenu(result)
    }
    const renderMenu = () => {
        let titleRender = nodeData => (
            <p className="menu-title">
                { nodeData.title }
                <span className="menu-action" onClick={ create.bind(this, nodeData) }>新增</span>
                <span className="menu-action" onClick={ edit.bind(this, nodeData) }>修改</span>
                <span className="menu-action" onClick={ del.bind(this, nodeData.key) }>删除</span>
            </p>
        )
        return (
            <Tree
                className="menu-tree-list"
                blockNode
                titleRender={ titleRender }
                treeData={ treeMenu }
            />
        )
    }

    const create = item => {
        let trees = treeData.slice()
        trees.forEach(menu => {
            menu.disabled = false
        })
        setVisible(true)
        setModalTitle('新增菜单')
        setEditItem({ parentId: item.id })
        setTreeData(trees)
    }
    const edit = item => {
        let editItem = { ...item }
        // 设置菜单本身不可选为父级
        let trees = treeData.slice()
        trees.forEach(menu => {
            if(item.id === menu.id){
                menu.disabled = true
            }else{
                menu.disabled = false
            }
        })
        setVisible(true)
        setModalTitle('修改菜单')
        setEditItem(editItem)
        setTreeData(trees)
    }
    const onCancel = () => {
        setVisible(false)
    }
    const onOk = values => {
        let url = '';
        let data = values;
        if (editItem.id) {
            url = '/api/menu/update'
            data['id'] = editItem.id
        } else {
            url = '/api/menu/add'
        }
        http.post(url, data, { isLoading: true }).then(res => {
            message.success(modalTitle + '成功！')
            getMenuList()
            onCancel()
        })
    }
    const del = id => {
        Modal.confirm({
            title: '提示',
            content: '是否确认要删除？',
            onOk: () => {
                http.post('/api/menu/delete', { id }, { isLoading: true }).then((res) => {
                    message.success('删除成功！')
                    getMenuList()
                })
            }
        })
    }

    return (
        <div className="content">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/home">首页</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>菜单管理</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ marginBottom: 20 }}>
                <Button type="primary" onClick={ create }><PlusOutlined /> 新增菜单</Button>
            </div>
            <Spin spinning={ isLoading }>
                <div style={{ minHeight: 300 }}>
                    { renderMenu() }
                </div>
            </Spin>
            
            <ModalForm
                title={ modalTitle }
                visible={ visible }
                record={ editItem }
                treeData={ treeData }
                onOk={ onOk }
                onCancel={ onCancel }
            />
        </div>
    )
}
export default SysMenu;
