import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Breadcrumb, Row, Col, Table, Form, Input, Select, DatePicker, Button, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useAntdTable from '@/assets/component/AntdTable';
import moment from 'moment';

import http from '@/utils/http';
import ModalForm from './ModalForm';

const Fund = () => {
    const isAdmin = sessionStorage.isAdmin === '1';
    const userInfo = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : {};

    const [searchForm] = Form.useForm();
    const [platformList, setPlatformList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [editItem, setEditItem] = useState({});

    useEffect(() => {
        getPlatformList()
    }, []) // eslint-disable-line

    const getPlatformList = () => {
        http.post('/api/platform/listAll').then(res => {
            setPlatformList(res)
        })
    }

    const getTableData = ({current, pageSize}, searchData) => {
        if(searchData.dateRange){
            searchData.startDate = moment(searchData.dateRange[0]).format('YYYY-MM-DD')
            searchData.endDate = moment(searchData.dateRange[1]).format('YYYY-MM-DD')
            delete searchData.dateRange
        }

        let data = {
            ...searchData,
            currentPage: current,
            pageSize,
            byOperationDate: '1'
        }
        if(!isAdmin){
            data['clientId'] = userInfo.clientId
        }

        return http.post('/api/fund/list', data).then(res => ({
            list: res.list,
            total: res.total
        }))
    }
    const { tableProps, search } = useAntdTable(getTableData, {
        defaultPageSize: 10,
        form: searchForm
    });

    const { submit, reset, reload } = search;

    let listColumns = [
        { title: '日期', dataIndex: 'operationDate', align: 'center', render: text => moment(text).format('YYYY-MM-DD') },
        { title: '客户姓名', dataIndex: 'clientName', align: 'center' },
        { title: '类型', dataIndex: 'typeName', align: 'center',
            render: (text, record) => (
                <p>
                    { record.typeCode === '1' && <strong className="txt-green">{ text }</strong> }
                    { record.typeCode === '2' && <strong className="txt-red">{ text }</strong> }
                    { record.typeCode === '3' && <strong className="txt-yellow">{ text }</strong> }
                </p>
            )
        },
        { title: '数额', dataIndex: 'money', align: 'center', render: text => `${text} $` },
        { title: '平台', dataIndex: 'platformName', align: 'center' },
        { title: '账号', dataIndex: 'account', align: 'center' },
        { title: '内转目标账号', dataIndex: 'accountTarget', align: 'center' }
    ]
    if(isAdmin){
        listColumns.push({
            title: '操作', align: 'center', width: 120,
            render: record => (
                <div className="table-actions">
                    <Button size="small" type="link" onClick={ edit.bind(this, record) }>编辑</Button>
                    <Button size="small" type="link" onClick={ delItem.bind(this, record.id) }>删除</Button>
                </div>
            )
        })
    }

    // 新增 & 编辑
    const create = () => {
        setVisible(true)
        setModalTitle('新增')
        setEditItem({})
    }
    const edit = record => {
        let item = { ...record };
        item.operationDate = moment(item.operationDate);
        item.platform = { key: item.platformCode, label: item.platformName };
        item.type = { key: item.typeCode, label: item.typeName };
        item.accountItem = { key: item.accountId, label: `${item.account}（${item.clientName}）` };
        item.accountTargetItem = { key: item.accountTargetId, label: `${item.accountTarget}（${item.clientName}）` };

        setVisible(true)
        setModalTitle('编辑')
        setEditItem(item)
    }
    const onQuery = values => {
        let url = ''
        let data = values
        
        if(editItem.id) {
            url = '/api/fund/update'
            data['id'] = editItem.id
        } else {
            url = '/api/fund/add'
        }

        http.post(url, data, { isLoading: true }).then(res => {
            message.success(modalTitle + '成功！')
            if(editItem.id) {
                reload()
            }else {
                submit()
            }
            onCancel()
        })
    }
    const onCancel = () => {
        setVisible(false)
    }
    // 删除
    const delItem = id => {
        Modal.confirm({
            title: '提示',
            content: '是否确认要删除？',
            onOk: () => {
                http.post('/api/fund/delete', { id }).then(() => {
                    message.success('删除成功！')
                    submit()
                })
            }
        })
    }
    return (
        <div className="content">
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to="/home">首页</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>出入金记录</Breadcrumb.Item>
            </Breadcrumb>
            <Form form={ searchForm } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} autoComplete="off">
                <Row type="flex" align="center" className="table-search">
                    <Col span={8}>
                        <Form.Item label="日期范围" name="dateRange">
                            <DatePicker.RangePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="平台" name="platformCode">
                            <Select allowClear placeholder="请选择平台">
                            {
                                platformList.map(item => (
                                    <Select.Option value={ item.id } key={ item.id }>{ item.name }</Select.Option>
                                ))
                            }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="类型" name="typeCode">
                            <Select allowClear placeholder="请选择类型">
                                <Select.Option value="1">入金</Select.Option>
                                <Select.Option value="2">出金</Select.Option>
                                <Select.Option value="3">内转</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="账号" name="account">
                            <Input placeholder="请输入账号" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="客户姓名" name="clientName">
                            <Input placeholder="请输入客户姓名" />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="search-btns">
                        <Button type="primary" onClick={ submit }>查询</Button>
                        <Button type="ghost" onClick={ reset }>重置</Button>
                        {
                            isAdmin &&
                            <Button type="primary" onClick={ create }><PlusOutlined /> 新增</Button>
                        }
                    </Col>
                </Row>
            </Form>
            <Table bordered size="small" rowKey="id" columns={ listColumns } {...tableProps} />
            <ModalForm
                visible={ visible }
                title={ modalTitle }
                detail={ editItem }
                platformList={ platformList }
                onOk={ onQuery }
                onCancel={ onCancel }
            />
        </div>
    )
}
export default Fund;