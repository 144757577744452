import { useEffect } from 'react';
import * as echarts from 'echarts';

const EchartsPie = props => {
    const chartId = props.id || 'pie';

    useEffect(() => {
        if(props.data.length === 0){
            return
        }
        renderChart()
    }, [props.data]) // eslint-disable-line

    const renderChart = () => {
        let myChart = echarts.getInstanceByDom(document.getElementById(chartId));
        if(!myChart){
            myChart = echarts.init(document.getElementById(chartId));
        }
        myChart.setOption({
            color: props.color || ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: 0,
                left: 'center'
            },
            series: [
                {
                    name: props.title,
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 5,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: {
                        show: false
                    },
                    data: props.data
                }
            ]
        })
    }

    return (
        <div id={ chartId } style={{ height: props.height }}></div>
    )
}
export default EchartsPie