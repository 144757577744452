import axios from 'axios';
import { message } from 'antd';
import utils from './index';
const ajaxUrl = ''

axios.defaults.timeout = 10000000;
axios.interceptors.request.use(config => {
    // 在这里设置请求头与携带token信息
    if(sessionStorage.getItem('token')){
        config.headers.Authorization = sessionStorage.getItem('token')
    }

    return config;
})

// 添加响应拦截器
axios.interceptors.response.use(
    (res) => {
        if(res.data === 401){
            sessionStorage.setItem('token', '')
            window.location.href = '/login'
        }
        if(res.data.code !== 200){
            message.destroy()
            if(res.data.msg){
                message.error(res.data.msg)
            }
            if(document.querySelector('.full-loading')){
                document.querySelector('.full-loading').remove()
            }
            return 'error'
        }else{
            if(res.config.responseType !== 'blob'){
                return res.data.data
            }else{
                return res.data
            }
        }
    },
    (error) => {
        if(document.querySelector('.full-loading')){
            document.querySelector('.full-loading').remove()
        }
        if(!error.response){
            return
        }
        if(error.response && error.response.status === 401){
            sessionStorage.setItem('token', '')
            window.location.href = '/login'
        }else{
            if(error.response.data.msg){
                message.error(error.response.data.msg)
            }else{
                message.error('网络异常')
            }
        }
        return Promise.reject(error);
    }
)

const http = {
    get: function (url, params, opts) {
        return new Promise((resolve, reject) => {
            if( params && !params.params ){
                params = { params:params }
            }
            if(opts && opts.isLoading){
                utils.showLoading()
            }
            axios.get(ajaxUrl + url, params).then(res => {
                if(opts && opts.isLoading){
                    utils.hideLoading()
                }
                if(res === 'error') return;
                resolve(res);
            }).catch(err => {
                if(opts && opts.isLoading){
                    utils.hideLoading()
                }
                reject(err);
            });
        });
    },
    post: function (url, params, opts) {
        return new Promise((resolve, reject) => {
            if(!params){
                params = {}
            }
            if(opts && opts.isLoading){
                utils.showLoading()
            }
            axios.post(ajaxUrl + url, params).then(res => {
                if(opts && opts.isLoading){
                    utils.hideLoading()
                }
                if(res === 'error'){
                    reject(res);
                    return
                };
                resolve(res);
            }).catch(err => {
                if(opts && opts.isLoading){
                    utils.hideLoading()
                }
                reject(err);
            });
        });
    },
    postDownFile: function (url, params, opts) {
        return new Promise((resolve, reject) => {
            if (!params) {
                params = {}
            }
            if (opts && opts.isLoading) {
                utils.showLoading()
            }
            axios({ // 用axios发送post请求
                method: 'post',
                url: ajaxUrl + url, // 请求地址
                data: params, // 参数
                responseType: 'blob' // 表明返回服务器返回的数据类型
            }).then(res => { // 处理返回的文件流
                utils.hideLoading()
                if(!res){
                    return
                }
                if(res.type === 'application/json'){
                    var reader = new FileReader();
                    reader.readAsText(res)
                    reader.onload = file => {
                        let result = JSON.parse(file.currentTarget.result)
                        if(result.response === 'error'){
                            message.error(result.error)
                        }
                    }
                }else{
                    if(window.navigator.msSaveBlob){
                        try{
                            console.log(res)
                            var blobObject = new Blob([res]); 
                            window.navigator.msSaveBlob(blobObject, opts.fileName); 
                        }
                        catch(e){
                            console.log(e);
                        }
                    }else{
                        let fileUrl = URL.createObjectURL(res);
                        const aLink = document.createElement('a');
                        let filename = opts.fileName;
                        aLink.download = filename;

                        aLink.style.display = 'none'
                        aLink.href = fileUrl
                        document.body.appendChild(aLink)
                        aLink.click()
                        URL.revokeObjectURL(aLink.href) // 释放URL 对象
                        document.body.removeChild(aLink)
                    }
                }
                resolve(res);
            }).catch(err => {
                if (opts && opts.isLoading) {
                    utils.hideLoading()
                }
                reject(err);
            });
        });
    }
}

export default http

// import http from "http"
// http.post