import { useState, useEffect } from 'react';
import { Modal, Row, Upload, message, Radio, Image, Button } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import http from '@/utils/http';

const UploadFile = props => {
    const [type, setType] = useState('');
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [previewVisible, setPreviewVisible] = useState(false);

    useEffect(() => {
        setType(props.type)
        setFileList(props.fileList)
    }, [props.type, props.fileList])

    const changeType = e => {
        setType(e.target.value)
    }
    const beforeUpload = e => {
        return false
    }
    const handleChange = e => {
        if(!type){
            message.error('请选择附加文档类型')
            return
        }
        let typeName = ''
        switch(type){
            case '1':
                typeName = '身份证人像面';
                break;
            case '2':
                typeName = '身份证国徽面';
                break;
            case '3':
                typeName = '银行卡正面';
                break;
            case '4':
                typeName = '银行卡背面';
                break;
            case '5':
                typeName = '驾驶证';
                break;
            case '6':
                typeName = '护照';
                break;
            case '7':
                typeName = '户口本本人页';
                break;
            default:
                typeName = ''
        }
        const formData = new FormData();
        formData.append('file', e.file);
        formData.append('clientId', props.clientId)
        formData.append('type', type)
        formData.append('typeName', typeName)

        setLoading(true)
        http.post('/api/client/uploadFile', formData).then(res => {
            setFileList([res])
            setLoading(false)
        })
    }
    const deleteFile = () => {
        let file = fileList[0]
        Modal.confirm({
            title: '提示',
            content: '确定删除吗？',
            onOk: () => {
                http.post('/api/client/deleteFile', { id: file.id }).then(res => {
                    setFileList()
                })
            }
        })
    }

    const uploadButton = (
        <div>
            { loading ? <LoadingOutlined /> : <PlusOutlined /> }
            <div style={{ marginTop: 8 }}>选择文件</div>
        </div>
    );
    return (
        <div className="upload-box">
            <Row type="flex" justify="space-between" style={{ height: 24, marginBottom: 10 }}>
                <strong>{ props.title }</strong>
                {
                    props.title === '附加'  &&
                    <Radio.Group value={ type } onChange={ changeType } style={{ marginLeft: 15 }}>
                        <Radio value="5">驾驶证</Radio>
                        <Radio value="6">护照</Radio>
                        <Radio value="7">户口本本人页</Radio>
                    </Radio.Group>
                }
                <p>
                {
                    fileList.length > 0 && <Button type="primary" size="small" onClick={ deleteFile }>删除</Button>
                }
                </p>
            </Row>
            <Upload
                listType="picture-card"
                className="file-uploader"
                beforeUpload={ beforeUpload }
                onChange={ handleChange }
                accept="image/*"
                disabled={ fileList.length > 0 }
                fileList={[]}
            >
                {
                    fileList.length > 0 ?
                    <Image
                        style={{ maxHeight: '100%' }}
                        src={ fileList[0].thumbUrl }
                        preview={{
                            src: previewUrl,
                            visible: previewVisible,
                            onVisibleChange: (visible, prevVisible) => {
                                if(visible){
                                    http.post('/api/client/getOriginalImage', { id: fileList[0].id }, { isLoading: true }).then(res => {
                                        setPreviewVisible(true)
                                        setPreviewUrl(res)
                                    })
                                }else{
                                    setPreviewVisible(false)
                                }
                            }
                        }}
                    /> :
                    uploadButton
                }
            </Upload>
        </div>
    )
}
export default UploadFile;