import { Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import http from '@/utils/http';
import logo from '@/assets/images/logo.png';
import '@/assets/style/login.less';

const Login = () => {
    const history = useHistory();
    const [form] = Form.useForm();

    const doLogin = () => {
        form.validateFields().then(values => {
            if(!values.username){
                message.error('请输入你的账号');
                return
            }
            if(!values.password){
                message.error('请输入你的密码');
                return
            }
            http.post('/api/login', values, { isLoading: true }).then(res => {
                sessionStorage.setItem('userInfo', JSON.stringify(res.userInfo))
                sessionStorage.setItem('isAdmin', res.userInfo.roleName === '系统管理员' ? '1' : '0')
                sessionStorage.setItem('token', res.token)
                history.push('/home')
            })
        })
    }
    
    return (
        <div className="login-wrap">
            <div className="login-box">
                <div className="login-title">业务管理系统</div>
                {/* <img src={ logo } alt="" style={{ display: 'block', margin: '0 auto', width: 200 }} /> */}
                <Form form={ form } onFinish={ doLogin } className="login-form" autoComplete="off">
                    <Form.Item name="username">
                        <Input prefix={<UserOutlined />} size="large" placeholder="账号" />
                    </Form.Item>
                    <Form.Item name="password">
                        <Input.Password prefix={<LockOutlined />} size="large" placeholder="密码" />
                    </Form.Item>
                    <Button size="large" type="primary" className="login-btn" block htmlType="submit" style={{ marginTop: 30 }}>登录</Button>
                </Form>
            </div>
        </div>
    )
}
export default Login;