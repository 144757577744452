import { useEffect } from 'react';
import { Modal, Form, Input } from 'antd';

const ModalForm = props => {
    const [ form ] = Form.useForm();
    useEffect(() => {
        if(!props.visible){
            return
        }
        form.resetFields();
        form.setFieldsValue(props.detail);
    }, [props.visible]) // eslint-disable-line

    const queryForm = () => {
        form.validateFields().then(values => {
            props.onOk(values)
        })
    }
    const cancel = () => {
        props.onCancel()
    }
    return (
        <Modal
            title={ props.title }
            centered
            visible={ props.visible }
            onOk={ queryForm }
            onCancel={ cancel }
            forceRender
        >
            <Form form={ form } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} autoComplete="off">
                <Form.Item
                    label="角色名称"
                    name="roleName"
                    rules={[{ required: true, message: '请输入角色名称' }]}
                >
                    <Input placeholder="请输入角色名称" />
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ModalForm