import React from 'react';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'

import Login from '@/pages/login' //登陆
import LayoutPage from '@/pages/layout' //布局
import Home from '@/pages/home' //首页

import SysUser from '@/pages/system/user' //用户管理
import SysRole from '@/pages/system/role' //角色管理
import SysMenu from '@/pages/system/menu' //菜单管理
import SysPlatform from '@/pages/system/platform' //开户平台管理
import SysFinancialType from '@/pages/system/financialType' //理财种类管理
// 客户管理
import ClientList from '@/pages/client/list' //客户列表
import ClientLevel from '@/pages/client/level' //客户级别
import Account from '@/pages/account' //账户列表
// 运营管理
import Fund from '@/pages/fund' //出入金记录
import Profit from '@/pages/profit' //分润记录
// 统计
import StatClient from '@/pages/statistics/client' //客户统计

// 路由
const RouterView = () => (
	<BrowserRouter>
		<Switch>
			<Route path="/login" component={ Login } />
			<Route path="/" component={ LayoutPage } />
		</Switch>
	</BrowserRouter>
)

// 子路由
const ChildrenRouter = () => (
	<Switch>
		<Route path="/home" component={ Home } />
		<Route path="/sys/menu" exact component={ SysMenu } />
		<Route path="/sys/role" exact component={ SysRole } />
		<Route path="/sys/user" exact component={ SysUser } />
		<Route path="/sys/platform" exact component={ SysPlatform } />
		<Route path="/sys/financialType" exact component={ SysFinancialType } />
		
		<Route path="/client/list" exact component={ ClientList } />
		<Route path="/client/level" exact component={ ClientLevel } />
		<Route path="/account" exact component={ Account } />

		<Route path="/fund" exact component={ Fund } />
		<Route path="/profit" exact component={ Profit } />
		
		<Route path="/statistics/client/:clientId" exact component={ StatClient } />
		
		<Redirect to="/home" />
	</Switch>
)
export {
	RouterView,
	ChildrenRouter
}
