import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Layout, Menu, Tooltip, Row, Modal } from 'antd';
import { LogoutOutlined, HomeOutlined } from '@ant-design/icons';
import  * as Icon from '@ant-design/icons';
import { ChildrenRouter } from '@/router';

import http from '@/utils/http';
import logo from '@/assets/images/logo_small.png'
import '@/assets/style/layout.less';

const { Sider, Header, Content } = Layout;
const SubMenu = Menu.SubMenu;

const LayoutPage = () => {
    const history = useHistory();
    const [menus, setMenus] = useState([]);
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        if(!sessionStorage.userInfo){
            history.push('/login')
            return
        }
        setUserInfo(sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')) : {})
        getRoleMenuList()
    }, []) // eslint-disable-line

    const renderMenu = () => {
        return (
            <Menu mode="inline" theme="dark">
                <Menu.Item key="1" icon={ <HomeOutlined /> }>
                    <NavLink to="/home">首页</NavLink>
                </Menu.Item>
                { renderMenuItems(menus) }
            </Menu>
        )
    }
    const renderMenuItems = menus => {
        return (
            menus.map(menu => {
                if(menu.children){
                    return (
                        <SubMenu key={ menu.id } icon={ (menu.icon && Icon[menu.icon]) ? React.createElement(Icon[menu.icon]) : null } title={ menu.name }>
                            { renderMenuItems(menu.children)}
                        </SubMenu>
                    )
                }else {
                    return (
                        <Menu.Item key={ menu.id } icon={ (menu.icon && Icon[menu.icon]) ? React.createElement(Icon[menu.icon]) : null }>
                            <NavLink to={ menu.url }>
                                { menu.name }
                            </NavLink>
                        </Menu.Item>
                    )
                }
            })
        )
    }
    const getRoleMenuList = () => {
        let data = {
            id: JSON.parse(sessionStorage.userInfo).roleId
        }
        http.post('/api/role/menuList', data).then(res => {
            let result = []
            let mapJson = {}
            res.forEach(item => {
                mapJson[item.id] = item
            })
            res.forEach(item => {
                item.key = item.id;
                item.title = item.name;

                let parent = mapJson[item.parentId]
                if(parent){
                    (parent.children || (parent.children = [])).push(item)
                }else{
                    result.push(item)
                }
            })
            setMenus(result)
        })
    }
    const logOut = () => {
        Modal.confirm({
            title: '提示',
            content: '您确定退出登录吗？',
            onOk: () => {
                http.post('/api/logout', {}, { isLoading: true }).then(() => {
                    sessionStorage.setItem('userInfo', '')
                    history.push('/login')
                })
            }
        })
    }
    return (
        <Layout style={{ height: '100vh', backgroundColor: '#f6f7fb' }}>
            <Header className="header" id="header">
                <Row type="flex" justify="space-between" align="center">
                    <div className="side-logo">
                        <img src={ logo } alt="" />
                        <span>业务管理系统</span>
                    </div>
                    <Row type="flex" align="middle" style={{ paddingRight: 20 }}>
                        <span>欢迎，{ userInfo.name }！</span>
                        <Tooltip title="退出登录">
                            <LogoutOutlined
                                style={{ fontSize: 18, cursor: 'pointer', marginLeft: 20 }}
                                onClick={ logOut }
                            />
                        </Tooltip>
                    </Row>
                </Row>
            </Header>
            <Layout>
                <Sider
                    trigger={null}
                    theme="dark"
                    width={ 184 }
                >
                    <div style={{ paddingTop: 10 }}>
                        { renderMenu() }
                    </div>
                </Sider>
                <Layout className="main-layout">
                    <Content className="main">
                        <ChildrenRouter />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    )
}
export default LayoutPage;