import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Avatar, Divider, Descriptions, Card, Spin, Image, Empty, Button } from 'antd';
import http from '@/utils/http';

import * as echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/tree';
import 'echarts/lib/component/tooltip';
import IconBase64 from './IconBase64';
import AvatarMan from '@/assets/images/avatar-man.png';
import AvatarWoman from '@/assets/images/avatar-woman.png';

const ClientDetail = props => {
    const isAdmin = sessionStorage.isAdmin === '1';
    const history = useHistory();
    const [clientId, setClientId] = useState(props.id);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState({});
    const [bankInfo, setBankInfo] = useState({});
    const [accountList, setAccountList] = useState([]);
    const [fileList, setFileList] = useState([]);

    const [memberLoaded, setMemberLoaded] = useState(false);
    const [memberList, setMemberList] = useState([]);

    const [previewUrl, setPreviewUrl] = useState('');
    const [previewVisible, setPreviewVisible] = useState(false);

    useEffect(() => {
        getDetail()
        getMembers()
    }, [clientId]) // eslint-disable-line

    const getDetail = () => {
        let data = {
            id: clientId
        }
        setLoading(true)
        http.post('/api/client/detail', data).then(res => {
            setLoading(false);
            setDetail(res.detail);
            setBankInfo(res.bankInfo);
            setAccountList(res.accountList);
            setFileList(res.fileList)
        })
    }
    const getMembers = () => {
        let url = isAdmin ? '/api/client/getAllMember' : '/api/client/getDirectMember'
        let data = {
            id: clientId
        }
        setMemberLoaded(false)
        http.post(url, data).then(res => {
            setMemberLoaded(true)
            setMemberList(res)
        })
    }

    useEffect(() => {
        if(memberList.length === 0) {
            return
        }
        renderGroupTree()
    }, [memberList]) // eslint-disable-line

    const renderGroupTree = () => {
        let myChart = echarts.init(document.getElementById('tree'));
        myChart.setOption({
            tooltip: {
                trigger: 'item',
                triggerOn: 'mousemove',
                formatter: param => {
                    return param.data.name
                }
            },
            series:[
                {
                    type: 'tree',
                    id: 0,
                    name: 'tree',
                    data: memberList,
                    orient: isAdmin ? 'LR' : 'TB',
        
                    top: 20,
                    left: 40,
                    bottom: 40,
                    right: 40,
                    
                    symbol: (value, params) => params.data.sex === '1' ? 'image://' + IconBase64.man : 'image://' + IconBase64.woman,
                    symbolSize: 32,
        
                    edgeShape: 'polyline',
                    initialTreeDepth: -1,
        
                    lineStyle: {
                        width: 1,
                        color: '#333'
                    },
                    itemStyle: {
                        color: '#1890ff'
                    },
        
                    label: {
                        position: 'bottom',
                        align: 'center',
                        fontSize: 14,
                        width: 50
                    },
        
                    expandAndCollapse: false
                }
            ]
        })
        if(isAdmin) {
            myChart.on('click', params => {
                if(params.data.id === clientId){
                    return
                }
                setClientId(params.data.id)
            });
        }
    }
    const goStat = () => {
        history.push('/statistics/client/' + clientId)
    }

    return (
        <div className="content" style={{ backgroundColor: 'transparent', padding: 0, boxShadow: 'none' }}>
            {
                !props.singlePage &&
                <div style={{ marginBottom: 15 }}><Button type="primary" onClick={ props.onCancel }>返回</Button></div>
            }
            <Spin spinning={ loading } size="large">
                <Row>
                    <Col span={8}>
                        <Card style={{ paddingLeft: '10%', paddingRight: '10%' }}>
                            <div style={{ textAlign: 'center', marginBottom: 20 }}>
                                <Avatar
                                    size={100}
                                    src={ detail.sex === '2' ? AvatarWoman : AvatarMan }
                                    style={{ backgroundColor: detail.sex === '1' ? '#096dd9' : '#f058ab' }}
                                />
                                <p style={{ fontSize: 24, marginTop: 10 }}>{detail.name}</p>
                            </div>
                            <Descriptions column={2}>
                                <Descriptions.Item label="手机号" span={2}>{detail.telephone}</Descriptions.Item>
                                <Descriptions.Item label="身份证号" span={2}>{detail.idCard}</Descriptions.Item>
                                <Descriptions.Item label="电子邮箱" span={2}>{detail.email}</Descriptions.Item>
                                <Descriptions.Item label="用户类型">{detail.levelName}</Descriptions.Item>
                                <Descriptions.Item label="分润比例">{detail.profitRatio} %</Descriptions.Item>
                                <Descriptions.Item label="分润比例">{detail.tcRatio} %</Descriptions.Item>
                                <Descriptions.Item label="分佣金额">{detail.commissionMoney} $</Descriptions.Item>
                                {
                                    detail.parentName &&
                                    <Descriptions.Item label="上级代理" span={2}>{detail.parentName}</Descriptions.Item>
                                }
                                <Descriptions.Item label="地址" span={2}>{detail.provinceName}{detail.cityName}{detail.countryName}{detail.address}</Descriptions.Item>
                                <Descriptions.Item label="备注" span={2}>{detail.remark}</Descriptions.Item>
                            </Descriptions>
                            <Divider />
                            <div className="detail-title">银行卡信息</div>
                            {
                                bankInfo.id ?
                                <Descriptions size="small" column={1}>
                                    <Descriptions.Item label="持有人姓名">{bankInfo.holderName}</Descriptions.Item>
                                    <Descriptions.Item label="银行名称">{bankInfo.bankName}</Descriptions.Item>
                                    <Descriptions.Item label="银行支行全称">{bankInfo.subBankName}</Descriptions.Item>
                                    <Descriptions.Item label="收款人银行账号">{bankInfo.bankNum}</Descriptions.Item>
                                </Descriptions> :
                                <Empty />
                            }
                            <Divider />
                            <Button block type="primary" onClick={ goStat }>查看数据统计</Button>
                        </Card>
                    </Col>
                    <Col span={16} style={{ paddingLeft: 20 }}>
                        <Card>
                            <div className="detail-title">开户信息</div>
                            <Row type="flex" gutter={[10, 10]}>
                                {
                                    accountList.map(item => (
                                        <Col key={item.id} style={{ width: 480 }}>
                                            <Descriptions size="small" title={item.platformName} className="account-item" column={2}>
                                                <Descriptions.Item label="开户账号">{item.account}</Descriptions.Item>
                                                <Descriptions.Item label="账户类型">{item.accountTypeName}</Descriptions.Item>
                                                <Descriptions.Item label="账户入金量">{item.firstMoney}</Descriptions.Item>
                                                <Descriptions.Item label="理财种类">{item.financialTypeName}</Descriptions.Item>
                                                <Descriptions.Item label="服务器">{item.server}</Descriptions.Item>
                                                <Descriptions.Item label="操作密码">{item.operationPassword}</Descriptions.Item>
                                                <Descriptions.Item label="观摩密码">{item.viewPassword}</Descriptions.Item>
                                                <Descriptions.Item label="注册时间">{item.operationDate}</Descriptions.Item>
                                                <Descriptions.Item label="账户状态">{item.stateName}</Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    ))
                                }
                            </Row>
                            {
                                accountList.length === 0 &&
                                <Empty />
                            }
                            <Divider />
                            <div className="detail-title">证件信息</div>
                            <Row gutter={30}>
                                {
                                    fileList.map(item => (
                                        <Col span={4} key={ item.id } style={{ textAlign: 'center' }}>
                                            <Image
                                                width="100%"
                                                src={ item.thumbUrl }
                                                preview={{
                                                    src: previewUrl,
                                                    visible: previewVisible,
                                                    onVisibleChange: (visible, prevVisible) => {
                                                        if(visible){
                                                            http.post('/api/client/getOriginalImage', { id: item.id }, { isLoading: true }).then(res => {
                                                                setPreviewUrl(res)
                                                                setPreviewVisible(true)
                                                            })
                                                        }else{
                                                            setPreviewVisible(false)
                                                        }
                                                    }
                                                }}
                                            />
                                            <p>{ item.typeName }</p>
                                        </Col>
                                    ))
                                }
                            </Row>
                            {
                                fileList.length === 0 &&
                                <Empty />
                            }
                            <Divider />
                            <div className="detail-title">代理团队</div>
                            {
                                memberLoaded &&
                                <div id="tree" style={{ height: 240 }}></div>
                            }
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}
export default ClientDetail;
