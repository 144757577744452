import { useState, useEffect } from 'react';
import { Row, Col, Statistic, Table, Form, Input, Select, Button, Modal, message, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useAntdTable from '@/assets/component/AntdTable';
import { useRequest } from 'ahooks';
import moment from 'moment';
import http from '@/utils/http';
import ModalForm from './ModalForm';
import AgentDetail from './AgentDetail';
import '../style.less';

const ProfitDetail = props => {
    const isAdmin = sessionStorage.isAdmin === '1';
    const userInfo = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : {};
    
    const detailItem = props.record;
    const [searchForm] = Form.useForm();
    const [totalData, setTotalData] = useState({});
    const [visible, setVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [editItem, setEditItem] = useState({});
    const [agentVisible, setAgentVisibie] = useState(false);

    useEffect(() => {
        getTotalReq.run()
    }, []) // eslint-disable-line

    const getTotalData = () => {
        return new Promise(resolve => {
            let data = {
                groupId: detailItem.id
            }
            if(!isAdmin) {
                data.clientId = userInfo.clientId
            }
            http.post('/api/profit/detailStat', data).then(res => {
                resolve(res)
            })
        })
    }
    const getTotalReq = useRequest(getTotalData, {
        onSuccess: res => {
            setTotalData(res)
        }
    })

    const getTableData = ({current, pageSize}, searchData) => {
        let data = {
            ...searchData,
            currentPage: current,
            pageSize,
            groupId: detailItem.id
        }
        if(!isAdmin) {
            data.clientId = userInfo.clientId
        }
        return http.post('/api/profit/detailList', data).then(res => ({
            list: res.list,
            total: res.total
        }))
    }
    const { tableProps, search } = useAntdTable(getTableData, {
        defaultPageSize: 10,
        form: searchForm
    });
    const { submit, reset, reload } = search;

    let listColumns = isAdmin ? [
        { title: '客户姓名', dataIndex: 'clientName', align: 'center' },
        { title: '账号', dataIndex: 'account', align: 'center' },
        { title: '入金量(USD)', dataIndex: 'firstMoney', align: 'center' },
        { title: '收益(USD)', dataIndex: 'money', align: 'center' },
        { title: '客户分润(USD)', dataIndex: 'clientMoney', align: 'center' },
        { title: '公司分润(USD)', dataIndex: 'companyMoney', align: 'center' },
        { title: '当日汇率', dataIndex: 'exchangeRatio', align: 'center' },
        { title: '客户分润(CNY)', dataIndex: 'clientMoney', align: 'center', render: (text, record) => `${(text * record.exchangeRatio).toFixed(2)}` },
        { title: '公司分润(CNY)', dataIndex: 'companyMoney', align: 'center', render: (text, record) => `${(text * record.exchangeRatio).toFixed(2)}` },
        { title: '代理', dataIndex: 'parentName', align: 'center' },
        { title: '代理分润(USD)', dataIndex: 'agentMoney', align: 'center' },
        { title: '交易手数', dataIndex: 'tradeHands', align: 'center' },
        { title: '代理分佣(USD)', dataIndex: 'agentCommission', align: 'center' },
        { title: '完成分润', dataIndex: 'stateName', align: 'center', render: (text, record) => <strong className={ record.stateCode === '1' ? 'txt-green' : ''}>{ text }</strong>},
        { title: '操作', align: 'center', width: 100,
            render: record => (
                <div className="table-actions">
                    <Button size="small" type="link" onClick={ edit.bind(this, record) }>编辑</Button>
                    <Button size="small" type="link" onClick={ delItem.bind(this, record.id) }>删除</Button>
                </div>
            )
        }
    ] : [
        { title: '客户姓名', dataIndex: 'clientName', align: 'center' },
        { title: '账号', dataIndex: 'account', align: 'center' },
        { title: '入金量(USD)', dataIndex: 'firstMoney', align: 'center' },
        { title: '收益(USD)', dataIndex: 'money', align: 'center' },
        { title: '客户分润(USD)', dataIndex: 'clientMoney', align: 'center' },
        { title: '当日汇率', dataIndex: 'exchangeRatio', align: 'center' },
        { title: '客户分润(CNY)', dataIndex: 'clientMoney', align: 'center', render: (text, record) => `${(text * record.exchangeRatio).toFixed(2)}` }
    ]
    // 新增 & 编辑
    const create = () => {
        setVisible(true)
        setModalTitle('新增')
        setEditItem({})
    }
    const edit = record => {
        setVisible(true)
        setModalTitle('编辑')
        setEditItem({...record})
    }
    const onQuery = values => {
        let url = ''
        let data = values
        
        if (editItem.id) {
            url = '/api/profit/updateDetail'
            data['id'] = editItem.id
        } else {
            url = '/api/profit/addDetail'
        }
        data['groupId'] = detailItem.id

        http.post(url, data, { isLoading: true }).then(res => {
            message.success(modalTitle + '成功！')
            editItem.id ? reload() : submit();
            onCancel()
            getTotalReq.run()
        })
    }
    const onCancel = () => {
        setVisible(false)
    }
    // 删除
    const delItem = id => {
        Modal.confirm({
            title: '提示',
            content: '是否确认要删除？',
            onOk: () => {
                http.post('/api/profit/deleteDetail', { id }).then(() => {
                    message.success('删除成功！')
                    submit()
                    getTotalReq.run()
                })
            }
        })
    }
    const showAgent = () => {
        setAgentVisibie(true)
    }
    const cancelAgent = () => {
        setAgentVisibie(false)
    }
    return (
        <>
            <div className="content" style={{ display: agentVisible ? 'none' : 'block' }}>
                <Row justify="space-between" style={{ marginBottom: 15 }}>
                    <Col>
                        <Button type="primary" onClick={ props.onCancel }>返回</Button>
                        <strong style={{ marginLeft: 15, fontSize: 16 }}>
                            【{moment(detailItem.beginTime).format('YYYY-MM-DD')} 至 {moment(detailItem.endTime).format('YYYY-MM-DD')}】 分润记录
                        </strong>
                    </Col>
                    <Col>
                        <Button type="primary" ghost onClick={ showAgent }>查看代理分润</Button>
                    </Col>
                </Row>
                <Spin spinning={ getTotalReq.loading }>
                    <Row gutter={20} style={{ marginBottom: 20 }}>
                        <Col style={{ width: '20%' }}>
                            <div className="profit-total" style={{ backgroundColor: '#6bb7fd' }}>
                                <strong>总收益</strong>
                                <Row justify="space-between" align="middle">
                                    <span>USD</span>
                                    <Statistic value={ totalData.totalMoney } precision={2} />
                                </Row>
                                <Row justify="space-between" align="middle">
                                    <span>CNY</span>
                                    <Statistic value={ totalData.totalMoney * 6.4 } precision={2} />
                                </Row>
                            </div>
                        </Col>
                        <Col style={{ width: '20%' }}>
                            <div className="profit-total" style={{ backgroundColor: '#ff776d' }}>
                                <strong>客户总分润</strong>
                                <Row justify="space-between" align="middle">
                                    <span>USD</span>
                                    <Statistic value={ totalData.totalClientMoney } precision={2} />
                                </Row>
                                <Row justify="space-between" align="middle">
                                    <span>CNY</span>
                                    <Statistic value={ totalData.totalClientMoney * 6.4 } precision={2} />
                                </Row>
                            </div>
                        </Col>
                        {
                            isAdmin &&
                            <>
                                <Col style={{ width: '20%' }}>
                                    <div className="profit-total" style={{ backgroundColor: '#39af3e' }}>
                                        <strong>公司总分润</strong>
                                        <Row justify="space-between" align="middle">
                                            <span>USD</span>
                                            <Statistic value={ totalData.totalCompanyMoney } precision={2} />
                                        </Row>
                                        <Row justify="space-between" align="middle">
                                            <span>CNY</span>
                                            <Statistic value={ totalData.totalCompanyMoney * 6.4 } precision={2} />
                                        </Row>
                                    </div>
                                </Col>
                                <Col style={{ width: '20%' }}>
                                    <div className="profit-total" style={{ backgroundColor: '#f7a327' }}>
                                        <strong>代理总分润</strong>
                                        <Row justify="space-between" align="middle">
                                            <span>USD</span>
                                            <Statistic value={ totalData.totalAgentMoney } precision={2} />
                                        </Row>
                                        <Row justify="space-between" align="middle">
                                            <span>CNY</span>
                                            <Statistic value={ totalData.totalAgentMoney * 6.4 } precision={2} />
                                        </Row>
                                    </div>
                                </Col>
                                <Col style={{ width: '20%' }}>
                                    <div className="profit-total" style={{ backgroundColor: '#2db9cb' }}>
                                        <strong>代理总佣金</strong>
                                        <Row justify="space-between" align="middle">
                                            <span>USD</span>
                                            <Statistic value={ totalData.totalAgentCommission } precision={2} />
                                        </Row>
                                        <Row justify="space-between" align="middle">
                                            <span>CNY</span>
                                            <Statistic value={ totalData.totalAgentCommission * 6.4 } precision={2} />
                                        </Row>
                                    </div>
                                </Col>
                            </>
                        }
                    </Row>
                </Spin>
                <Form form={ searchForm } labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} autoComplete="off">
                    <Row type="flex" align="center" className="table-search">
                        <Col span={6}>
                            <Form.Item label="客户姓名" name="clientName">
                                <Input placeholder="请输入客户姓名" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="完成分润" name="stateCode">
                                <Select allowClear placeholder="请选择是否完成分润">
                                    <Select.Option value="1">是</Select.Option>
                                    <Select.Option value="0">否</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12} className="search-btns">
                            <Button type="primary" onClick={ submit }>查询</Button>
                            <Button type="ghost" onClick={ reset }>重置</Button>
                            {
                                isAdmin &&
                                <Button type="primary" onClick={ create }><PlusOutlined /> 新增</Button>
                            }
                        </Col>
                    </Row>
                </Form>
                <Table bordered size="small" rowKey="id" className="mini-table" columns={ listColumns } {...tableProps} />
                <ModalForm
                    visible={ visible }
                    title={ modalTitle }
                    detail={ editItem }
                    onOk={ onQuery }
                    onCancel={ onCancel }
                />
            </div>
            {
                agentVisible &&
                <AgentDetail
                    groupId={ detailItem.id }
                    onCancel={ cancelAgent }
                />
            }
        </>
    )
}
export default ProfitDetail;