import React, { Component } from 'react';
import { DatePicker, Row, Col, Card, Statistic, Button, Table } from 'antd';
import http from '@/utils/http';
import moment from 'moment';

class StatClient extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clientId: this.props.match.params.clientId,
            startDate: undefined,
            endDate: undefined,
            clientDetail: {},
            detail: {},
            accountList: [],
            fundList: [],
            profitList: [],
            tcList: [],
            childList: []
        }
    }
    componentDidMount() {
        this.getClient()
        this.getDetail()
    }
    goBack = () => {
        this.props.history.goBack();
    }
    render() {
        const { detail } = this.state;
        return (
            <div className="content" style={{ backgroundColor: 'transparent', padding: 0, boxShadow: 'none' }}>
                <Card size="small">
                    <Row type="flex" style={{ padding: '0 10px', height: 30, lineHeight: '30px' }}>
                        <Col span={12}>
                            <Button type="primary" onClick={ this.goBack }>返回</Button>
                            <strong style={{ display: 'inline-block', fontSize: 20, marginLeft: 20 }}>{ this.state.clientDetail.name }</strong>
                            （{ this.state.clientDetail.levelName }）
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <DatePicker.RangePicker onChange={ this.changeRange } />
                            <Button type="primary" onClick={ this.getDetail } style={{ marginLeft: 10 }}>查询</Button>
                        </Col>
                    </Row>
                </Card>
                <Row gutter={ 20 } style={{ marginTop: 15 }}>
                    <Col span={ this.state.childList.length === 0 && 6 } flex="1">
                        <Card title="个人总金量">
                            <Row type="flex" align="center" justify="space-between">
                                <Row type="flex" align="center" className="stat-icon" style={{ backgroundColor: '#3eaf07' }}>
                                    <i className="iconfont icon-fund" />
                                </Row>
                                <Statistic title="USD" value={ detail.totalMoney } precision={2} />
                            </Row>
                        </Card>
                    </Col>
                    {
                        this.state.childList.length > 0 &&
                        <Col flex="1">
                            <Card title="团队总金量">
                                <Row type="flex" align="center" justify="space-between">
                                    <Row type="flex" align="center" className="stat-icon" style={{ backgroundColor: '#96d5f7' }}>
                                        <i className="iconfont icon-fund" />
                                    </Row>
                                    <Statistic title="USD" value={ detail.totalChildMoney } precision={2} />
                                </Row>
                            </Card>
                        </Col>
                    }
                    <Col span={ this.state.childList.length === 0 && 6 } flex="1">
                        <Card title="总分润">
                            <Row type="flex" align="center" justify="space-between">
                                <Row type="flex" align="center" className="stat-icon" style={{ backgroundColor: '#faad14' }}>
                                    <i className="iconfont icon-profit" />
                                </Row>
                                <Statistic title="CNY" value={ detail.totalProfit } precision={2} />
                            </Row>
                        </Card>
                    </Col>
                    {
                        this.state.childList.length > 0 &&
                        <React.Fragment>
                            <Col flex="1">
                                <Card title="总提成">
                                    <Row type="flex" align="center" justify="space-between">
                                        <Row type="flex" align="center" className="stat-icon" style={{ backgroundColor: '#9f36d9' }}>
                                            <i className="iconfont icon-xiaoshouticheng" />
                                        </Row>
                                        <Statistic title="CNY" value={ detail.totalTc } precision={2} />
                                    </Row>
                                </Card>
                            </Col>
                            <Col flex="1">
                                <Card title="总佣金">
                                    <Row type="flex" align="center" justify="space-between">
                                        <Row type="flex" align="center" className="stat-icon" style={{ backgroundColor: '#ff4d4f' }}>
                                            <i className="iconfont icon-commission" />
                                        </Row>
                                        <Statistic title="CNY" value={ detail.totalCommission } precision={2} />
                                    </Row>
                                </Card>
                            </Col>
                            <Col flex="1">
                                <Card title="团队人数">
                                    <Row type="flex" align="center" justify="space-between">
                                        <Row type="flex" align="center" className="stat-icon" style={{ backgroundColor: '#096dd9' }}>
                                            <i className="iconfont icon-client" />
                                        </Row>
                                        <Statistic title="人数" value={ detail.totalGroupNums } />
                                    </Row>
                                </Card>
                            </Col>
                        </React.Fragment>
                    }
                </Row>
                <Card size="small" title="我的账号" style={{ marginTop: 15 }}>
                    { this.renderAccountTable() }
                </Card>
                <Card size="small" title="入金记录" style={{ marginTop: 15 }}>
                    { this.renderFundTable() }
                </Card>
                <Card size="small" title="分润记录" style={{ marginTop: 15 }}>
                    { this.renderProfitTable() }
                </Card>
                {
                    this.state.tcList.length > 0 &&
                    <Card size="small" title="提成分佣记录" style={{ marginTop: 15 }}>
                        { this.renderTcTable() }
                    </Card>
                }
                {
                    this.state.childList.length > 0 &&
                    <Card size="small" title="团队成员" style={{ marginTop: 15 }}>
                        { this.renderChildTable() }
                    </Card>
                }
            </div>
        )
    }
    getClient = () => {
        let data = {
            id: this.state.clientId
        }
        http.post('/api/client/detail', data).then(res => {
            this.setState({
                clientDetail: res.detail
            })
        })
    }
    changeRange = range => {
        this.setState({
            startDate: range ? moment(range[0]).format('YYYY-MM-DD') : undefined,
            endDate: range ? moment(range[1]).format('YYYY-MM-DD') : undefined
        })
    }
    getDetail = () => {
        let data = {
            clientId: this.state.clientId,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        }
        http.post('/api/statistics/getClientStat', data, { isLoading: true }).then(res => {
            this.setState({
                detail: res.stat,
                accountList: res.accountList,
                fundList: res.fundList,
                profitList: res.profitList,
                tcList: res.tcList,
                childList: res.childList
            })
        })
    }

    renderAccountTable() {
        let columns = [
            { title: '注册时间', dataIndex: 'operationDate', align: 'center', render: text => moment(text).format('YYYY-MM-DD') },
            { title: '客户姓名', dataIndex: 'clientName', align: 'center' },
            { title: '开户平台', dataIndex: 'platformName', align: 'center' },
            { title: '账号', dataIndex: 'account', align: 'center' },
            { title: '账号类型', dataIndex: 'accountTypeName', align: 'center' },
            { title: '入金量', dataIndex: 'firstMoney', align: 'center', render: text => `${text} $` },
            { title: '当前金量', dataIndex: 'money', align: 'center', render: text => `${text} $` },
            { title: '总分润', dataIndex: 'totalProfitMoney', align: 'center', render: text => `${text} $` },
            { title: '理财种类', dataIndex: 'financialTypeName', align: 'center' },
            { title: '服务器', dataIndex: 'server', align: 'center' },
            { title: '操作密码', dataIndex: 'operationPassword', align: 'center' },
            { title: '观摩密码', dataIndex: 'viewPassword', align: 'center' },
            { title: '账户状态', dataIndex: 'stateName', align: 'center', render: (text, record) => <strong className={ record.stateCode === '1' ? 'txt-green' : 'txt-red' }>{ text }</strong> }
        ]
        const props = {
            rowKey: 'id',
            size: 'small',
            columns,
            dataSource: this.state.accountList,
            pagination: this.state.accountList.length < 11 ? false : true
        }
        return (
            <Table className="list-table" {...props} />
        )
    }

    renderFundTable() {
        let columns = [
            { title: '日期', dataIndex: 'operationDate', align: 'center', render: text => moment(text).format('YYYY-MM-DD') },
            { title: '客户姓名', dataIndex: 'clientName', align: 'center' },
            { title: '类型', dataIndex: 'typeName', align: 'center',
                render: (text, record) => (
                    <p>
                        { record.typeCode === '1' && <strong className="txt-green">{ text }</strong> }
                        { record.typeCode === '2' && <strong className="txt-red">{ text }</strong> }
                        { record.typeCode === '3' && <strong className="txt-yellow">{ text }</strong> }
                    </p>
                )
            },
            { title: '数额', dataIndex: 'money', align: 'center', render: text => `${text} $` },
            { title: '平台', dataIndex: 'platformName', align: 'center' },
            { title: '账号', dataIndex: 'account', align: 'center' },
            { title: '内转目标账号', dataIndex: 'accountTarget', align: 'center' }
        ]
        const props = {
            rowKey: 'id',
            size: 'small',
            columns,
            dataSource: this.state.fundList,
            pagination: this.state.fundList.length < 11 ? false : true
        }
        return (
            <Table className="list-table" {...props} />
        )
    }

    renderProfitTable() {
        let columns = [
            { title: '日期', dataIndex: 'operationDate', align: 'center', render: text => moment(text).format('YYYY-MM-DD') },
            { title: '客户姓名', dataIndex: 'clientName', align: 'center' },
            { title: '理财种类', dataIndex: 'financialTypeName', align: 'center' },
            { title: '平台', dataIndex: 'platformName', align: 'center' },
            { title: '账号', dataIndex: 'account', align: 'center' },
            { title: '数额(USD)', dataIndex: 'money', align: 'center' },
            { title: '当日汇率', dataIndex: 'exchangeRatio', align: 'center' },
            { title: '分润比例', dataIndex: 'profitRatio', align: 'center', render: text => `${text}%` },
            { title: '分润(CNY)', dataIndex: 'clientMoney', align: 'center' }
        ]
        const props = {
            rowKey: 'id',
            size: 'small',
            columns,
            dataSource: this.state.profitList,
            pagination: this.state.profitList.length < 11 ? false : true
        }
        return (
            <Table className="list-table" {...props} />
        )
    }
    renderTcTable() {
        let columns = [
            { title: '日期', dataIndex: 'operationDate', align: 'center', render: text => moment(text).format('YYYY-MM-DD') },
            { title: '客户姓名', dataIndex: 'clientName', align: 'center' },
            { title: '理财种类', dataIndex: 'financialTypeName', align: 'center' },
            { title: '平台', dataIndex: 'platformName', align: 'center' },
            { title: '账号', dataIndex: 'account', align: 'center' },
            { title: '数额(USD)', dataIndex: 'money', align: 'center' },
            { title: '当日汇率', dataIndex: 'exchangeRatio', align: 'center' },
            { title: '提成分润(CNY)', dataIndex: 'agentMoney', align: 'center' },
            { title: '分佣(CNY)', dataIndex: 'agentCommission', align: 'center' }
        ]
        const props = {
            rowKey: 'id',
            size: 'small',
            columns,
            dataSource: this.state.tcList,
            pagination: this.state.tcList.length < 11 ? false : true
        }
        return (
            <Table className="list-table" {...props} />
        )
    }
    renderChildTable() {
        let columns = [
            { title: '编号', dataIndex: 'number', align: 'center' },
            { title: '姓名', dataIndex: 'name', align: 'center' },
            {
                title: '性别', dataIndex: 'sex', align: 'center',
                render: text => text === '1' ? '男' : '女'
            },
            { title: '手机号码', dataIndex: 'telephone', align: 'center' },
            { title: '身份证号', dataIndex: 'idCard', align: 'center' },
            { title: '省份', dataIndex: 'provinceName', align: 'center' },
            { title: '城市', dataIndex: 'cityName', align: 'center' }
        ]
        const props = {
            rowKey: 'id',
            size: 'small',
            columns,
            dataSource: this.state.childList,
            pagination: this.state.childList.length < 11 ? false : true
        }
        return (
            <Table className="list-table" {...props} />
        )
    }
    checkChild = id => {
        this.props.history.push('/client/detail/' + id)
    }
}

export default StatClient;