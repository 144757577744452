import { useEffect } from 'react';
import { Modal, Form, Input, InputNumber } from 'antd';

const ModalForm = props => {
    const [ form ] = Form.useForm();
    useEffect(() => {
        if(!props.visible){
            return
        }
        form.resetFields();
        form.setFieldsValue(props.detail);
    }, [props.visible]) // eslint-disable-line

    const queryForm = () => {
        form.validateFields().then(values => {
            props.onOk(values)
        })
    }
    const cancel = () => {
        props.onCancel()
    }
    return (
        <Modal
            title={ props.title }
            centered
            visible={ props.visible }
            onOk={ queryForm }
            onCancel={ cancel }
            forceRender
        >
            <Form form={ form } labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} autoComplete="off">
                <Form.Item
                    label="级别名称"
                    name="name"
                    rules={[{ required: true, message: '请输入级别名称' }]}
                >
                    <Input placeholder="请输入级别名称" />
                </Form.Item>
                <Form.Item
                    label="分润比例"
                    name="profitRatio"
                    rules={[{ required: true, message: '请输入分润比例' }]}
                >
                    <InputNumber min={0} max={100} formatter={value => `${value}%`} placeholder="请输入分润比例" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="提成比例"
                    name="tcRatio"
                    rules={[{ required: true, message: '请输入提成比例' }]}
                >
                    <InputNumber min={0} max={100} formatter={value => `${value}%`} placeholder="请输入提成比例" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="分佣金额($)"
                    name="commissionMoney"
                    rules={[{ required: true, message: '请输入分佣金额' }]}
                >
                    <InputNumber min={0} max={100} formatter={value => `${value} $`} placeholder="请输入分佣金额" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="排序"
                    name="sort"
                    rules={[{ required: true, message: '请输入排序' }]}
                >
                    <Input placeholder="请输入排序，例如 1" />
                </Form.Item>
            </Form>
        </Modal>
    )
}
export default ModalForm